import EditCustomer from "./EditCustomer";
import RemoveCustomer from "./RemoveCustomer";
import ArchiveCustomer from "./ArchiveCustomer";
import UnArchiveCustomer from "./UnArchiveCustomer";
import { Link } from "react-router-dom";

function CustomerRow({customer}) {
	return (
		<tr>
			<td><Link to={`/subCustomers/${customer.id}/${customer.fullName}`}>{customer.fullName}</Link></td>
			<td>{customer.description}</td>
			<td>
				<div className={"d-flex gap-2"}>
					<EditCustomer customer={customer}/>
					{customer.archived ? <UnArchiveCustomer customer={customer} /> : <ArchiveCustomer customer={customer} />}
					<RemoveCustomer customer={customer}/>
				</div>
			</td>
		</tr>
	);
}

export default CustomerRow;
