import {useContext, useState} from "react";
import Spinner from "../../Spinner";
import customerContext from "../../../contexts/customers/customerContext";
import subCustomerContext from "../../../contexts/subCustomers/subCustomerContext";
import Error from "../../alerts/Error";

const initialSubCustomer = {
    email: '',
	firstName: '',
	lastName: '',
    customerId: '',
	rights: ''
};

function AddSubCustomer() {
	const {createSubCustomer, loading, customerId} = useContext(subCustomerContext);

	const [subCustomer, setSubCustomer] = useState({...initialSubCustomer, customerId: customerId});
	const [error, setError] = useState(null);

	async function onSubmit(evt) {
		evt.preventDefault();
		setError(null);

		try {
			await createSubCustomer(subCustomer);
		}
		catch (e) {
			console.error(e);
			setError(e.message);
		}
		setSubCustomer(initialSubCustomer);
	}

	function onChange(evt) {
		switch (evt.target.type) {
			case "checkbox":
				setSubCustomer({
					...subCustomer,
					[evt.target.id]: evt.target.checked
				});
				break;
			default:
				setSubCustomer({
					...subCustomer,
					[evt.target.id]: evt.target.value
				});
		}
	}

	return (
		<div>
			<button className={"btn btn-success"} data-bs-toggle={"modal"} data-bs-target={"#addSubCustomerModal"}>Add sub-customer</button>
			<div className={"modal fade"} id={"addSubCustomerModal"} tabIndex="-1" aria-labelledby="addSubCustomerModalLabel" aria-hidden="true">
				<div className={"modal-dialog modal-lg"}>
					<div className={"modal-content"}>
						<form onSubmit={onSubmit}>
							<div className={"modal-header"}>
								<h5 className={"modal-title"} id={"addSubCustomerModalLabel"}>Add sub-customer</h5>
								<button type={"button"} className={"btn-close"} data-bs-dismiss={"modal"} />
							</div>
							<div className={"modal-body"}>
								{error && <Error message={error} /> }
								<div className="row mb-3">
									<div className={"col"}>
										<label htmlFor="firstName" className={"form-label"}>First name:</label>
										<input type="text" id={"firstName"} value={subCustomer.firstName} className={"form-control"} placeholder={"William"} required={true} onChange={onChange}/>
									</div>
									<div className={"col"}>
										<label htmlFor="lastName" className={"form-label"}>Last name:</label>
										<input type="text" id={"lastName"} value={subCustomer.lastName} className={"form-control"} placeholder={"Wong"} required={true} onChange={onChange}/>
									</div>
								</div>

								<div className={"mb-3"}>
									<label htmlFor={"email"} className={"form-label"}>Email:</label>
									<textarea id={"email"} className={"form-control"} name={"email"} value={subCustomer.email || ''} placeholder={"blabla"} onChange={onChange}/>
								</div>
								<div className={"mb-3"}>
									<label htmlFor="rights" className={"form-label"}>Rights:</label>
									<select id="rights" className={"form-select"} required={true} value={subCustomer.rights} onChange={onChange}>
										<option value="">Select an option</option>
										<option value="view">View only</option>;
										<option value="view/export">View/Export</option>;
									</select>
								</div>
							</div>
							<div className={"modal-footer justify-content-between"}>
								<button type={"button"} className={"btn btn-secondary"} data-bs-dismiss={"modal"}>Close</button>
								<button type={"submit"} className={"btn btn-success"} disabled={loading}>{loading ? <Spinner />: 'Add sub-customer'}</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AddSubCustomer;
