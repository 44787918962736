import types from "./languagePairTypes";

export default (state, action) => {
	switch (action.type) {
		case types.SET_LOADING:
			return {
				...state,
				loading: !state.loading
			};
		case types.SET_LANGUAGE_PAIRS:
			return {
				...state,
				loading: false,
				languagePairs: action.payload
			};
		default:
			return state;
	}
};