import EditWorkType from "./EditWorkType";

function WorkTypeRow({workType}) {
	return (
		<tr>
			<td>{workType.name}</td>
			<td>{workType.description}</td>
			<td>{workType.unitValue}</td>
			<td>{workType.minUnitValue}</td>
			<td>{workType.maxUnitValue}</td>
			<td>{workType.unitName.name}</td>
			<td>{workType.groupA}</td>
			<td>{workType.groupB}</td>
			<td>{workType.groupC}</td>
			<td>{workType.problemCount}</td>
			<td>
				<div className={"d-flex gap-2"}>
					<EditWorkType workType={workType}/>
				</div>
			</td>
		</tr>
	);
}

export default WorkTypeRow;