import EditLanguage from "./EditLanguage";
import RemoveLanguage from "./RemoveLanguage";

function LanguageRow({language}) {
	return (
		<tr>
			<td>{language.name}</td>
			<td>{language.shortCode}</td>
			<td>{language.langTag}</td>
			<td>{language.direction}</td>
			<td><input type={"checkbox"} checked={language.wordToken} readOnly={true} /></td>
			<td>
				<div className={"d-flex gap-2"}>
					{/*<EditLanguage language={language} dispatch={dispatch}/>*/}
					<RemoveLanguage language={language} />
				</div>
			</td>
		</tr>
	);
}

export default LanguageRow;