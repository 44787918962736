import context from "./languagePairContext";
import React, {useContext, useEffect, useReducer} from "react";
import reducer from "./languagePairReducer";
import types from "./languagePairTypes";
import {NotificationContext} from "../NotificationContext";
import {AuthContext} from "../AuthContext";

export default ({children}) => {
	const initialState = {
		loading: false,
		languagePairs: []
	};

	const [state, dispatch] = useReducer(reducer, initialState);
    const {token, setToken} = useContext(AuthContext);
	const {addToast} = useContext(NotificationContext);

	useEffect(() => {
		const abortController = new AbortController();

		(async () => {
			try {
				await getLanguagePairs(abortController);
			}
			catch (e) {
				console.error(e);
				dispatch({
					type: types.SET_LOADING
				});
			}
		})();

		return () => {
			abortController.abort();
		}
	}, []);

	// Get languagePairs
	async function getLanguagePairs(abortController) {
		dispatch({
			type: types.SET_LOADING
		});

		const response = await fetch(process.env.REACT_APP_endPoint + '/graphql', {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				'Authorization': 'Bearer ' + token
			},
			body: JSON.stringify({
				query: `
					query getLanguagePairs {
					  getLanguagePairs {
						id
						sourceLanguage {
						  name
						}
						targetLanguage {
						  name
						}
						group
						languagePairGroupId
					  }
					}
				`
			}),
			signal:abortController.signal
		});
		if (response.status === 401) {
			setToken(null);
		}
		else {
			const json = await response.json();

			if (json.errors) {
				for (let i = 0; i < json.errors.length; i++) {
					const {data} = json.errors[i];

					if (data && data.length > 0) {
						for (let j = 0; j < data.length; j++) {
							addToast({
								message: json.errors[i].message,
								description: data[j]
							});
						}
					} else {
						addToast({
							message: json.errors[i].message,
							description: ""
						});
					}
				}
				dispatch({
					type: types.SET_LOADING
				});
			} else {
				dispatch({
					type: types.SET_LANGUAGE_PAIRS,
					payload: json.data.getLanguagePairs.map(value => {
						return {
							...value,
							sourceLanguage: value.sourceLanguage.name,
							targetLanguage: value.targetLanguage.name
						}
					})
				});
			}
		}
	}

	// Remove languagepair
	async function removeLanguagePair(languagePairId) {
		dispatch({
			type: types.SET_LOADING
		});

		const response = await fetch(process.env.REACT_APP_endPoint + '/graphql', {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				'Authorization': 'Bearer ' + token
			},
			body: JSON.stringify({
				query: `
					mutation removeLanguagePair($languagePairId: ID!) {
					  removeLanguagePair(languagePairId:$languagePairId)
					}
				`,
				variables: {
					languagePairId
				}
			})
		});
		if (response.status === 401) {
			setToken(null);
		}
		else {
			const json = await response.json();

			if (json.errors) {
				for (let i = 0; i < json.errors.length; i++) {
					const {data} = json.errors[i];

					if (data && data.length > 0) {
						for (let j = 0; j < data.length; j++) {
							addToast({
								message: json.errors[i].message,
								description: data[j]
							});
						}
					} else {
						addToast({
							message: json.errors[i].message,
							description: ""
						});
					}
				}
				dispatch({
					type: types.SET_LOADING
				});
			} else {
				dispatch({
					type: types.SET_LANGUAGE_PAIRS,
					payload: [...state.languagePairs.filter(value => value.id !== languagePairId)]
				});
			}
		}
	}

	// Add languagepair
	async function createLanguagePair(languagePair) {
		dispatch({
			type: types.SET_LOADING
		});

		console.log();

		const response = await fetch(process.env.REACT_APP_endPoint + '/graphql', {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				'Authorization': 'Bearer ' + token
			},
			body: JSON.stringify({
				query: `
					mutation createLanguagePair($languagePair: CreateLanguagePairInput!) {
					  createLanguagePair(languagePair: $languagePair) {
						id
						sourceLanguage {
						  name
						}
						targetLanguage {
						  name
						}
						group
						languagePairGroupId
					  }
					}
				`,
				variables: {
					languagePair: {
						...languagePair
					}
				}
			})
		});
		if (response.status === 401) {
			setToken(null);
		}
		else {
			const json = await response.json();

			if (json.errors) {
				for (let i = 0; i < json.errors.length; i++) {
					const {data} = json.errors[i];

					if (data && data.length > 0) {
						for (let j = 0; j < data.length; j++) {
							addToast({
								message: json.errors[i].message,
								description: data[j]
							});
						}
					} else {
						addToast({
							message: json.errors[i].message,
							description: ""
						});
					}
				}

				dispatch({
					type: types.SET_LOADING
				});
			} else {
				dispatch({
					type: types.SET_LANGUAGE_PAIRS,
					payload: [...state.languagePairs, {
						...json.data.createLanguagePair,
						sourceLanguage: json.data.createLanguagePair.sourceLanguage.name,
						targetLanguage: json.data.createLanguagePair.targetLanguage.name
					}]
				});
			}
		}
	}

	// Edit languagepair
	async function editLanguagePair(languagePairId, languagePair) {
		dispatch({
			type: types.SET_LOADING
		});

		console.log();

		const response = await fetch(process.env.REACT_APP_endPoint + '/graphql', {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				'Authorization': 'Bearer ' + token
			},
			body: JSON.stringify({
				query: `
					mutation editLanguagePair($languagePair: EditLanguagePairInput!, $languagePairId: ID!) {
					  editLanguagePair(languagePair: $languagePair, languagePairId: $languagePairId) {
						id
						sourceLanguage {
						  name
						}
						targetLanguage {
						  name
						}
						group
						languagePairGroupId
					  }
					}
				`,
				variables: {
					languagePair: {
						...languagePair
					},
					languagePairId
				}
			})
		});
		if (response.status === 401) {
			setToken(null);
		}
		else {
			const json = await response.json();

			if (json.errors) {
				for (let i = 0; i < json.errors.length; i++) {
					const {data} = json.errors[i];

					if (data && data.length > 0) {
						for (let j = 0; j < data.length; j++) {
							addToast({
								message: json.errors[i].message,
								description: data[j]
							});
						}
					} else {
						addToast({
							message: json.errors[i].message,
							description: ""
						});
					}
				}

				dispatch({
					type: types.SET_LOADING
				});
			} else {
				const newLanguagePairs = [...state.languagePairs];
				newLanguagePairs[newLanguagePairs.findIndex(value => value.id === languagePairId)] = {
					...json.data.editLanguagePair,
					sourceLanguage: json.data.editLanguagePair.sourceLanguage.name,
					targetLanguage: json.data.editLanguagePair.targetLanguage.name
				};
				dispatch({
					type: types.SET_LANGUAGE_PAIRS,
					payload: newLanguagePairs
				});
			}
		}
	}

	return (
		<context.Provider value={{
			...state,
			getLanguagePairs,
			createLanguagePair,
			removeLanguagePair,
			editLanguagePair
		}}>
			{children}
		</context.Provider>
	);
};