import RemovePmjJob from "./RemovePmjJob";

function PmjJobRow({pmjJob}) {
	return (
		<tr>
			<td>{pmjJob.name}</td>
			<td>{pmjJob.freelancer?.fullName}</td>
			<td>{pmjJob.description}</td>
			<td>
				{pmjJob.invoiced ? "This job is already invoiced." :
					<div className={"d-flex gap-2"}>
						<RemovePmjJob pmjJob={pmjJob}/>
					</div>
				}
			</td>
		</tr>
	);
}

export default PmjJobRow;
