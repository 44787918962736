import PmRow from "./PmRow";
import {useContext, useEffect} from "react";
import resize from "../../../../util/resize";
import pmContext from "../../../../contexts/pms/pmContext";
import Spinner from "../../../Spinner";

function Pms() {
	const {pms, loading} = useContext(pmContext);

	useEffect(() => {
		const items = [];

		try {
			resize(items);
		}
		catch (e) {
			console.error(e);
		}

		return () => {
			for (let item of items) {
				item.element.removeEventListener("mousedown", item.handler);
			}
		}
	}, []);

	return (
		<div className={"table-responsive overflow-auto"}>
			<table className={"table table-striped table-bordered mt-2"} id={"resizeMe"}>
				<thead className={"table-light"}>
				<tr>
					<th>Name</th>
					<th>Email address</th>
					<th>Pin code</th>
					<th>Actions</th>
				</tr>
				</thead>
				<tbody>
				{loading && <tr><td colSpan={4}><Spinner /></td></tr>}
					{pms.map(value => {
						return <PmRow pm={value} key={value.id}/>;
					})}
				</tbody>
			</table>
		</div>
	)
}

export default Pms;