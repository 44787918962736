import types from "./pmTypes";

export default (state, action) => {
	switch (action.type) {
		case types.SET_LOADING:
			return {
				...state,
				loading: !state.loading
			};
		case types.SET_PMS:
			return {
				...state,
				loading: false,
				pms: action.payload
			};
		default:
			return state;
	}
};