import {createContext} from "react";

export default createContext({
	customers: [],
	loading: false,
	getCustomers: () => {},
	createCustomer: () => {},
	editCustomer: () => {},
	removeCustomer: () => {},
	getCustomer: () => {},
	archiveCustomer: () => {},
	unArchiveCustomer: () => {}
});
