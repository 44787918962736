import {useContext, useState} from "react";
import Spinner from "../../../Spinner";
import pmContext from "../../../../contexts/pms/pmContext";
import Error from "../../../alerts/Error";

const initialPm = {
	firstName: '',
	lastName: '',
	email: '',
	pin: ''
};

function AddPm() {
	const [pm, setPm] = useState(initialPm);
	const {createPm, loading} = useContext(pmContext);
	const [error, setError] = useState(null);

	async function onSubmit(evt) {
		evt.preventDefault();
		setError(null);

		try {
			await createPm(pm);
		}
		catch (e) {
			console.error(e);
			setError(e.message);
		}
		setPm(initialPm);
	}

	function onChange(evt) {
		switch (evt.target.type) {
			case "checkbox":
				setPm({
					...pm,
					[evt.target.id]: evt.target.checked
				});
				break;
			default:
				setPm({
					...pm,
					[evt.target.id]: evt.target.value
				});
		}
	}

	return (
		<div>
			<button className={"btn btn-success"} data-bs-toggle={"modal"} data-bs-target={"#addPmModal"}>Add pm user</button>
			<div className={"modal fade"} id={"addPmModal"} tabIndex="-1" aria-labelledby="addPmModalLabel" aria-hidden="true">
				<div className={"modal-dialog modal-lg"}>
					<div className={"modal-content"}>
						<form onSubmit={onSubmit}>
							<div className={"modal-header"}>
								<h5 className={"modal-title"} id={"addPmModalLabel"}>Add pm user</h5>
								<button type={"button"} className={"btn-close"} data-bs-dismiss={"modal"} />
							</div>
							<div className={"modal-body"}>
								{error && <Error message={error} /> }
								<div className="row mb-3">
									<div className={"col"}>
										<label htmlFor="firstName" className={"form-label"}>First name:</label>
										<input type="text" id={"firstName"} value={pm.firstName} className={"form-control"} placeholder={"William"} required={true} onChange={onChange}/>
									</div>
									<div className={"col"}>
										<label htmlFor="lastName" className={"form-label"}>Last name:</label>
										<input type="text" id={"lastName"} value={pm.lastName} className={"form-control"} placeholder={"Wong"} required={true} onChange={onChange}/>
									</div>
								</div>

								<div className="row mb-3">
									<div className={"col"}>
										<label htmlFor="email" className={"form-label"}>Email address:</label>
										<input type="email" id={"email"} className={"form-control"} value={pm.email} placeholder={"william.wong@amazon.com"} required={true} onChange={onChange}/>
									</div>
									<div className={"col"}>
										<label htmlFor="pin" className={"form-label"}>Pin code:</label>
										<input type="text" id={"pin"} className={"form-control"} value={pm.pin} placeholder={"123456"} required={true} onChange={onChange}/>
									</div>
								</div>


							</div>
							<div className={"modal-footer justify-content-between"}>
								<button type={"button"} className={"btn btn-secondary"} data-bs-dismiss={"modal"}>Close</button>
								<button type={"submit"} className={"btn btn-success"}>{loading ? <Spinner />: 'Add pm user'}</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AddPm;
