import PageDiv from "../../PageDiv";
import SubCustomers from "./SubCustomers";
import AddSubCustomer from "./AddSubCustomer";
import SubCustomerState from "../../../contexts/subCustomers/SubCustomerState";
import { useParams } from "react-router-dom";
import CustomerState from "../../../contexts/customers/CustomerState";
import SubCustomersBreadcrums from "./SubCustomersBreadcrums";

function SubCustomersPage() {
	const {customerId, customerFullName} = useParams();

	return (
		<PageDiv>
			<CustomerState>
				<SubCustomerState customerId={customerId}>
					<SubCustomersBreadcrums customerFullName={customerFullName} />
					<AddSubCustomer />
					<hr />
					<SubCustomers />
				</SubCustomerState>
			</CustomerState>
		</PageDiv>
	)
}

export default SubCustomersPage;
