import EditCustomerTask from "./EditCustomerTask";
import RemoveCustomerTask from "./RemoveCustomerTask";

function CustomerTaskRow({customerTask}) {
	return (
		<tr>
			<td>{customerTask.customer.fullName}</td>
			<td>{customerTask.code}</td>
			<td>{customerTask.name}</td>
			<td>{customerTask.description}</td>
			<td dangerouslySetInnerHTML={{
				__html: customerTask.comments
			}}></td>
			<td>{customerTask.externalCosts}</td>
			<td>{customerTask.internalCosts}</td>
			<td>{customerTask.estimatedBonus}</td>
			<td>{customerTask.estimatedInvoiceValue}</td>
			<td>{customerTask.expectedDueDate}</td>
			<td>{customerTask.purchaseOrder ? customerTask.purchaseOrder.number : customerTask.poNumber}</td>
			<td>{customerTask.invoiceAmount}</td>
			<td>{customerTask.finalAmount}</td>
			<td>{customerTask.invoiceDate}</td>
			<td>{customerTask.invoiceNumber}</td>
			<td><input type={"checkbox"} checked={customerTask.invoiced} readOnly={true} /></td>

			<td>
				<div className={"d-flex gap-2"}>
					<EditCustomerTask customerTask={customerTask}/>
					<RemoveCustomerTask customerTask={customerTask}/>
				</div>
			</td>
		</tr>
	);
}

export default CustomerTaskRow;
