import EditSubCustomer from "./EditSubCustomer";
import RemoveSubCustomer from "./RemoveSubCustomer";

function SubCustomerRow({subCustomer}) {
	return (
		<tr>
			<td>{subCustomer.firstName}</td>
			<td>{subCustomer.lastName}</td>
			<td>{subCustomer.email}</td>
			<td>{subCustomer.rights}</td>
			<td>
				<div className={"d-flex gap-2"}>
					<EditSubCustomer subCustomer={subCustomer}/>
					<RemoveSubCustomer subCustomer={subCustomer}/>
				</div>
			</td>
		</tr>
	);
}

export default SubCustomerRow;
