import context from "./pmContext";
import React, {useContext, useEffect, useReducer} from "react";
import reducer from "./pmReducer";
import types from "./pmTypes";
import {NotificationContext} from "../NotificationContext";
import {AuthContext} from "../AuthContext";

export default ({children}) => {
	const initialState = {
		loading: false,
		pms: []
	};

	const [state, dispatch] = useReducer(reducer, initialState);
    const {token, setToken} = useContext(AuthContext);
	const {addToast} = useContext(NotificationContext);

	useEffect(() => {
		const abortController = new AbortController();

		(async () => {
			try {
				await getPms(abortController);
			}
			catch (e) {
				console.error(e);
				dispatch({
					type: types.SET_LOADING
				});
			}
		})();

		return () => {
			abortController.abort();
		};
	}, []);

	// Get pms
	async function getPms(abortController) {
		dispatch({
			type: types.SET_LOADING
		});

		const response = await fetch(process.env.REACT_APP_endPoint + '/graphql', {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				'Authorization': 'Bearer ' + token
			},
			body: JSON.stringify({
				query: `
					query getPms{
						getPms{
							id
							firstName
							lastName
							fullName
							email
							pin
							blocked
						}
					}`
			}),
			signal: abortController.signal
		});
		if (response.status === 401) {
			setToken(null);
		}
		else {
			const json = await response.json();

			if (json.errors) {
				for (let i = 0; i < json.errors.length; i++) {
					const {data} = json.errors[i];

					if (data && data.length > 0) {
						for (let j = 0; j < data.length; j++) {
							addToast({
								message: json.errors[i].message,
								description: data[j]
							});
						}
					} else {
						addToast({
							message: json.errors[i].message,
							description: ""
						});
					}
				}
				dispatch({
					type: types.SET_LOADING
				});
			} else {
				dispatch({
					type: types.SET_PMS,
					payload: json.data.getPms
				});
			}
		}
	}

	// Remove pm
	async function removePm(pmId) {
		dispatch({
			type: types.SET_LOADING
		});

		const response = await fetch(process.env.REACT_APP_endPoint + '/graphql', {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				'Authorization': 'Bearer ' + token
			},
			body: JSON.stringify({
				query: `
					mutation removePm ($pmId: ID!){
						removePm (pmId:$pmId) 
					}
				`,
				variables: {
					pmId
				}
			})
		});
		if (response.status === 401) {
			setToken(null);
		}
		else {
			const json = await response.json();

			if (json.errors) {
				for (let i = 0; i < json.errors.length; i++) {
					const {data} = json.errors[i];

					if (data && data.length > 0) {
						for (let j = 0; j < data.length; j++) {
							addToast({
								message: json.errors[i].message,
								description: data[j]
							});
						}
					} else {
						addToast({
							message: json.errors[i].message,
							description: ""
						});
					}
				}
				dispatch({
					type: types.SET_LOADING
				});
			} else {
				dispatch({
					type: types.SET_PMS,
					payload: [...state.pms.filter(value => value.id !== pmId)]
				});
			}
		}
	}

	// Add pm
	async function createPm(pm) {
		dispatch({
			type: types.SET_LOADING
		});

		const response = await fetch(process.env.REACT_APP_endPoint + '/graphql', {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				'Authorization': 'Bearer ' + token
			},
			body: JSON.stringify({
				query: `mutation createPm($pm: CreatePmInput!) {
						createPm(pm: $pm) {
							id
							firstName
							lastName
							fullName
							email
							pin
							blocked
						}
					}`,
				variables: {
					pm: {
						...pm,
						pin: parseInt(pm.pin)
					}
				}
			})
		});
		if (response.status === 401) {
			setToken(null);
		}
		else {
			const json = await response.json();

			if (json.errors) {
				for (let i = 0; i < json.errors.length; i++) {
					const {data} = json.errors[i];

					if (data && data.length > 0) {
						for (let j = 0; j < data.length; j++) {
							addToast({
								message: json.errors[i].message,
								description: data[j]
							});
						}
					} else {
						addToast({
							message: json.errors[i].message,
							description: ""
						});
					}
				}

				dispatch({
					type: types.SET_LOADING
				});
			} else {
				dispatch({
					type: types.SET_PMS,
					payload: [...state.pms, json.data.createPm]
				});
			}
		}
	}

	// Edit pm
	async function editPm(pmId, pm) {
		dispatch({
			type: types.SET_LOADING
		});

		const response = await fetch(process.env.REACT_APP_endPoint + '/graphql', {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				'Authorization': 'Bearer ' + token
			},
			body: JSON.stringify({
				query: `
					mutation editPm ($pmId: ID!, $pm: EditPmInput!) {
						editPm (pmId:$pmId, pm:$pm) {
							id
							firstName
							lastName
							fullName
							email
							pin
							blocked
						}
					}
				`,
				variables: {
					pmId,
					pm: {
						firstName: pm.firstName,
						lastName: pm.lastName,
						email: pm.email,
						pin: parseInt(pm.pin)
					}
				}
			})
		});
		if (response.status === 401) {
			setToken(null);
		}
		else {
			const json = await response.json();

			if (json.errors) {
				for (let i = 0; i < json.errors.length; i++) {
					const {data} = json.errors[i];

					if (data && data.length > 0) {
						for (let j = 0; j < data.length; j++) {
							addToast({
								message: json.errors[i].message,
								description: data[j]
							});
						}
					} else {
						addToast({
							message: json.errors[i].message,
							description: ""
						});
					}
				}
				dispatch({
					type: types.SET_LOADING
				});
			} else {
				const newPms = [...state.pms];
				newPms[newPms.findIndex(value => value.id === pmId)] = json.data.editPm;
				dispatch({
					type: types.SET_PMS,
					payload: newPms
				});
			}
		}
	}

	// Unblock pm
	async function unblockPm(pmId) {
		dispatch({
			type: types.SET_LOADING
		});

		const response = await fetch(process.env.REACT_APP_endPoint + '/graphql', {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				'Authorization': 'Bearer ' + token
			},
			body: JSON.stringify({
				query: `
					mutation unblockPm ($pmId: ID!){
						unblockPm (pmId:$pmId) 
					}
				`,
				variables: {
					pmId
				}
			})
		});
		if (response.status === 401) {
			setToken(null);
		}
		else {
			const json = await response.json();

			if (json.errors) {
				for (let i = 0; i < json.errors.length; i++) {
					const {data} = json.errors[i];

					if (data && data.length > 0) {
						for (let j = 0; j < data.length; j++) {
							addToast({
								message: json.errors[i].message,
								description: data[j]
							});
						}
					} else {
						addToast({
							message: json.errors[i].message,
							description: ""
						});
					}
				}
				dispatch({
					type: types.SET_LOADING
				});
			} else {
				const newPms = [...state.pms];
				newPms[newPms.findIndex(value => value.id === pmId)].blocked = false;

				dispatch({
					type: types.SET_PMS,
					payload: newPms
				});
			}
		}
	}

	return (
		<context.Provider value={{
			...state,
			getPms,
			createPm,
			editPm,
			removePm,
			unblockPm
		}}>
			{children}
		</context.Provider>
	);
};