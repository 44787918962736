import SortableHeader from "../../SortableHeader";
import LanguageRow from "./LanguageRow";
import {useContext, useEffect, useState} from "react";
import "../../../css/resize.css";
import resize from "../../../util/resize";
import Pagination from "../../Pagination";
import languageContext from "../../../contexts/language/languageContext";
import Spinner from "../../Spinner";
import LanguagePairRow from "../languagePairs/LanguagePairRow";

function Languages() {
	const {languages, loading} = useContext(languageContext);

	useEffect(() => {
		const items = [];

		try {
			resize(items);
		}
		catch (e) {
			console.error(e);
		}

		return () => {
			for (let item of items) {
				item.element.removeEventListener("mousedown", item.handler);
			}
		}
	}, []);

	return (
		<div className={"table-responsive overflow-auto"}>
			<table className={"table table-striped mt-2 table-bordered"} id={"resizeMe"}>
				<thead className={"table-light"}>
				<tr>
					<th>Name</th>
					<th>Short code</th>
					<th>Tag</th>
					<th>Direction</th>
					<th>Word token</th>
					<th>Actions</th>
				</tr>
				</thead>
				<tbody>

					{loading && <tr><td colSpan={6}><Spinner /></td></tr>}
						{languages.map(value => {
							return <LanguageRow language={value} key={value.id} />;
						})}

				</tbody>
			</table>
		</div>
	);
}

export default Languages;