import {useContext, useState} from "react";
import Spinner from "../../Spinner";
import languageContext from "../../../contexts/language/languageContext";
import Error from "../../alerts/Error";

const initialLanguage = {
	name: '',
	shortCode: '',
	langTag: '',
	languageDirectionId: '',
	wordToken: false
};

function AddLanguage() {
	const [language, setLanguage] = useState(initialLanguage);

	const {createLanguage, loading} = useContext(languageContext);
	const [error, setError] = useState(null);

	async function onSubmit(evt) {
		evt.preventDefault();
		setError(null);

		try {
			await createLanguage(language);
		}
		catch (e) {
			console.error(e);
			setError(e.message);
		}
		setLanguage(initialLanguage);
	}

	function onChange(evt) {
		switch (evt.target.type) {
			case "checkbox":
				setLanguage({
					...language,
					[evt.target.id]: evt.target.checked
				});
				break;
			default:
				setLanguage({
					...language,
					[evt.target.id]: evt.target.value
				});
		}
	}

	return (
		<div>
			<button className={"btn btn-success"} data-bs-toggle={"modal"} data-bs-target={"#addLanguageModal"}>Add language</button>
			<div className={"modal fade"} id={"addLanguageModal"} tabIndex="-1" aria-labelledby="addLanguageModalLabel" aria-hidden="true">
				<div className={"modal-dialog modal-lg"}>
					<div className={"modal-content"}>
						<form onSubmit={onSubmit}>
							<div className={"modal-header"}>
								<h5 className={"modal-title"} id={"addLanguageModalLabel"}>Add language</h5>
								<button type={"button"} className={"btn-close"} data-bs-dismiss={"modal"} />
							</div>
							<div className={"modal-body"}>
								{error && <Error message={error} /> }

								<div className={"mb-3"}>
									<label htmlFor="name" className={"form-label"}>Name:</label>
									<input type="text" id={"name"} className={"form-control"} placeholder={"Language name"} required={true} value={language.name} onChange={onChange}/>
								</div>
								<div className="row mb-3">
									<div className={"col"}>
										<label htmlFor="shortCode" className={"form-label"}>Short code:</label>
										<input type="text" id={"shortCode"} className={"form-control"} placeholder={"Language short code"} required={true} value={language.shortCode} onChange={onChange}/>
									</div>
									<div className={"col"}>
										<label htmlFor="langTag" className={"form-label"}>Tag:</label>
										<input type="text" id={"langTag"} className={"form-control"} placeholder={"Language tag"} required={true} value={language.langTag} onChange={onChange}/>
									</div>
								</div>

								<div className={"mb-3"}>
									<label htmlFor="languageDirectionId" className={"form-label"}>Direction:</label>
									<select id="languageDirectionId" className={"form-select"} required={true} value={language.languageDirectionId} onChange={onChange}>
										<option value="">Select an option</option>
										<option value="1">Left to right</option>
										<option value="2">Right to left</option>
									</select>
								</div>
								<div className="form-check">
									<input className="form-check-input" type="checkbox" value="" id="wordToken" checked={language.wordToken} onChange={onChange}/>
									<label className="form-check-label" htmlFor="wordToken">
										Language word token
									</label>
								</div>
							</div>
							<div className={"modal-footer justify-content-between"}>
								<button type={"button"} className={"btn btn-secondary"} data-bs-dismiss={"modal"}>Close</button>
								<button type={"submit"} className={"btn btn-success"} disabled={loading}>{loading ? <Spinner />: 'Add language'}</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	)
}

export default AddLanguage;
