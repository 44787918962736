import PageDiv from "../../../PageDiv";
import DevJobs from "./DevJobs";
import AddDevJob from "./AddDevJob";
import FreelancerState from "../../../../contexts/freelancers/FreelancerState";
import BatchState from "../../../../contexts/batches/BatchState";
import JobState from "../../../../contexts/jobs/JobState";

function DevsPage() {
	return (
		<PageDiv>
			<JobState workTypeName={'DEV'}>
				<BatchState workTypeName={'DEV'}>
					<FreelancerState workTypeName={'DEV'}>
						<AddDevJob />
					</FreelancerState>
				</BatchState>
				<hr />
				<DevJobs/>
			</JobState>
		</PageDiv>
	)
}

export default DevsPage;