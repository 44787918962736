import {useContext, useEffect, useState} from "react";
import Spinner from "../../../Spinner";
import pmContext from "../../../../contexts/pms/pmContext";
import Error from "../../../alerts/Error";

function RemovePm ({pm}) {
	const {loading, removePm} = useContext(pmContext);
	const [error, setError] = useState(null);

	useEffect(() => {
		return () => {
			const backdrops = document.getElementsByClassName("modal-backdrop fade show");
			for (let i = 0; i < backdrops.length; i++) {
				backdrops[i].remove();
			}
		}
	}, []);

	async function onSubmit(evt) {
		evt.preventDefault();
		setError(null);

		try {
			await removePm(pm.id);
		}
		catch (e) {
			console.error(e);
			setError(e.message);
		}
	}

	return (
		<>
			<button className={"btn btn-danger btn-sm"} data-bs-toggle={"modal"} data-bs-target={"#removePmModal" + pm.id}>Remove pm</button>
			<div className={"modal fade"} id={"removePmModal" + pm.id} tabIndex="-1" aria-labelledby={"removePmModalLabel" + pm.id} aria-hidden="true">
				<div className={"modal-dialog"}>
					<div className={"modal-content"}>
						<form onSubmit={onSubmit}>
							<div className={"modal-header"}>
								<h5 className={"modal-title"} id={"removePmModalLabel" + pm.id}>Remove pm</h5>
								<button type={"button"} className={"btn-close"} data-bs-dismiss={"modal"} />
							</div>
							<div className={"modal-body"}>
								{error && <Error message={error} /> }
								<span>Are you sure you want to remove {pm.firstName} {pm.lastName}?</span>
							</div>
							<div className={"modal-footer justify-content-between"}>
								<button type={"button"} className={"btn btn-secondary"} data-bs-dismiss={"modal"}>Close</button>
								<button type={"submit"} className={"btn btn-danger"}>{loading ? <Spinner />: 'Remove pm'}</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	)
}

export default RemovePm;
