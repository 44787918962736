import {useContext, useEffect, useState} from "react";
import Spinner from "../../Spinner";
import languagePairContext from "../../../contexts/languagePair/languagePairContext";
import languageContext from "../../../contexts/language/languageContext";
import {NotificationContext} from "../../../contexts/NotificationContext";
import {AuthContext} from "../../../contexts/AuthContext";
import languagePairGroupContext from "../../../contexts/languagePairGroup/languagePairGroupContext";
import Error from "../../alerts/Error";

const initialLanguagePair = {
	sourceId: '',
	targetId: '',
	languagePairGroupId: ''
};

function AddLanguagePair() {
	const [languagePair, setLanguagePair] = useState(initialLanguagePair);
	const {loading, createLanguagePair} = useContext(languagePairContext);
	const {languages} = useContext(languageContext);
	const {languagePairGroups} = useContext(languagePairGroupContext);
	const [error, setError] = useState(null);

	async function onSubmit(evt) {
		evt.preventDefault();
		setError(null);

		try {
			await createLanguagePair(languagePair);
		}
		catch (e) {
			console.error(e);
			setError(e.message);
		}
		setLanguagePair(initialLanguagePair);
	}

	function onChange(evt) {
		switch (evt.target.type) {
			case "checkbox":
				setLanguagePair({
					...languagePair,
					[evt.target.id]: evt.target.checked
				});
				break;
			default:
				setLanguagePair({
					...languagePair,
					[evt.target.id]: evt.target.value
				});
		}
	}

	return (
		<div>
			<button className={"btn btn-success"} data-bs-toggle={"modal"} data-bs-target={"#addLanguagePairModal"}>Add language pair</button>
			<div className={"modal fade"} id={"addLanguagePairModal"} tabIndex="-1" aria-labelledby="addLanguagePairModalLabel" aria-hidden="true">
				<div className={"modal-dialog modal-lg"}>
					<div className={"modal-content"}>
						<form onSubmit={onSubmit}>
							<div className={"modal-header"}>
								<h5 className={"modal-title"} id={"addLanguagePairModalLabel"}>Add language pair</h5>
								<button type={"button"} className={"btn-close"} data-bs-dismiss={"modal"} />
							</div>
							<div className={"modal-body"}>
								{error && <Error message={error} /> }

								<div className="row mb-3">
									<div className={"col"}>
										<label htmlFor="sourceId" className={"form-label"}>Source language:</label>
										<select id="sourceId" className={"form-select"} required={true} value={languagePair.sourceId} onChange={onChange}>
											<option value="">Select an option</option>
											{languages.map(value => {
												return <option key={value.id} value={value.id}>{value.name}</option>;
											})}
										</select>
									</div>
									<div className={"col"}>
										<label htmlFor="targetId" className={"form-label"}>Target language:</label>
										<select id="targetId" className={"form-select"} required={true} value={languagePair.targetId} onChange={onChange}>
											<option value="">Select an option</option>
											{languages.map(value => {
												return <option key={value.id} value={value.id}>{value.name}</option>;
											})}
										</select>
									</div>
								</div>

								<div className={"mb-3"}>
									<label htmlFor="languagePairGroupId" className={"form-label"}>Group:</label>
									<select id="languagePairGroupId" className={"form-select"} required={true} value={languagePair.languagePairGroupId} onChange={onChange}>
										<option value="">Select an option</option>
										{languagePairGroups.map(value => {
											return <option key={value.id} value={value.id}>{value.name}</option>;
										})}
									</select>
								</div>
							</div>
							<div className={"modal-footer justify-content-between"}>
								<button type={"button"} className={"btn btn-secondary"} data-bs-dismiss={"modal"}>Close</button>
								<button type={"submit"} className={"btn btn-success"}>{loading ? <Spinner />: 'Add language pair'}</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AddLanguagePair;
