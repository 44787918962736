import PageDiv from "../../PageDiv";
import CustomerTasks from "./CustomerTasks";
import AddCustomerTask from "./AddCustomerTask";
import ExportCustomerTasks from "./ExportCustomerTasks";
import CustomerTaskFilters from "./CustomerTaskFilters";
import State from "../../../contexts/customerTask/CustomerTaskState";
import CustomerState from "../../../contexts/customers/CustomerState";
import SortState from "../../../contexts/sort/SortState";
import PurchaseOrderState from "../../../contexts/purchaseOrders/purchaseOrderState";

function CustomerTasksPage() {
	return (
		<PageDiv>
			<PurchaseOrderState>
				<SortState initialColumn={'customerTasks.id'}>
					<State>
						<CustomerState>
							<div className="d-flex gap-2">
								<AddCustomerTask />
								<ExportCustomerTasks />
							</div>
							<hr />
							<CustomerTaskFilters />

							<hr />
							<CustomerTasks />
						</CustomerState>
					</State>
				</SortState>
			</PurchaseOrderState>
		</PageDiv>
	)
}

export default CustomerTasksPage;
