import {useContext, useState} from "react";
import Spinner from "../../Spinner";
import customerContext from "../../../contexts/customers/customerContext";
import Error from "../../alerts/Error";

function EditCustomer ({customer: initialCustomer}) {
	const [customer, setCustomer] = useState(initialCustomer);
	const {loading, editCustomer} = useContext(customerContext);
	const [error, setError] = useState(null);

	async function onSubmit(evt) {
		evt.preventDefault();
		setError(null);

		try {
			await editCustomer(initialCustomer.id, customer);
		}
		catch (e) {
			console.error(e);
			setError(e.message);
		}
	}

	function onChange(evt) {
		switch (evt.target.type) {
			case "checkbox":
				setCustomer({
					...customer,
					[evt.target.name]: evt.target.checked
				});
				break;
			default:
				setCustomer({
					...customer,
					[evt.target.name]: evt.target.value
				});
		}
	}

	return (
		<>
			<button className={"btn btn-secondary btn-sm"} data-bs-toggle={"modal"} data-bs-target={"#editCustomerModal" + customer.id}>Edit customer</button>
			<div className={"modal fade"} id={"editCustomerModal" + customer.id} tabIndex="-1" aria-labelledby={"editCustomerModalLabel" + customer.id} aria-hidden="true">
				<div className={"modal-dialog modal-lg"}>
					<div className={"modal-content"}>
						<form onSubmit={onSubmit}>
							<div className={"modal-header"}>
								<h5 className={"modal-title"} id={"editCustomerModalLabel" + customer.id}>Edit customer</h5>
								<button type={"button"} className={"btn-close"} data-bs-dismiss={"modal"} />
							</div>
							<div className={"modal-body"}>
								{error && <Error message={error} /> }

								<div className="mb-3">
									<label htmlFor={"fullName" + customer.id} className={"form-label"}>Full name:</label>
									<input type="text" id={"fullName" + customer.id} value={customer.fullName} className={"form-control"} placeholder={""} disabled={true} required={true}/>
								</div>

								<div className={"mb-3"}>
									<label htmlFor={"description" + customer.id} className={"form-label"}>Description:</label>
									<textarea id={"description" + customer.id} className={"form-control"} name={"description"} value={customer.description || ''} placeholder={"blabla"} onChange={onChange}/>
								</div>
							</div>
							<div className={"modal-footer justify-content-between"}>
								<button type={"button"} className={"btn btn-secondary"} data-bs-dismiss={"modal"}>Close</button>
								<button type={"submit"} className={"btn btn-primary"} disabled={loading}>{loading ? <Spinner />: 'Save'}</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
}

export default EditCustomer;
