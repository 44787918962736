import context from "./jobContext";
import React, {useContext, useEffect, useReducer} from "react";
import reducer from "./jobReducer";
import types from "./jobTypes";
import {NotificationContext} from "../NotificationContext";
import {AuthContext} from "../AuthContext";

export default ({children, workTypeName = null}) => {
	const initialState = {
		loading: false,
		jobs: [],
		workTypeName
	};

	const [state, dispatch] = useReducer(reducer, initialState);
    const {token, setToken} = useContext(AuthContext);
	const {addToast} = useContext(NotificationContext);

	useEffect(() => {
		const abortController = new AbortController();

		(async () => {
			try {
				await getJobs(abortController);
			}
			catch (e) {
				console.error(e);
				dispatch({
					type: types.SET_LOADING
				});
			}
		})();

		return () => {
			abortController.abort();
		};
	}, []);

	// Get pmj jobs
	async function getJobs(abortController) {
		dispatch({
			type: types.SET_LOADING
		});

		const response = await fetch(process.env.REACT_APP_endPoint + '/graphql', {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				'Authorization': 'Bearer ' + token
			},
			body: JSON.stringify({
				query: `
					query getJobs ($filters: JobFilters) {
						getJobs (filters:$filters) {
							id
							name
							price
							invoiced
							freelancer {
								id
								fullName
							}
							description
						}
					}`,
				variables: {
					filters: {
						workTypeName
					}
				}
			}),
			signal: abortController.signal
		});
		if (response.status === 401) {
			setToken(null);
		}
		else {
			const json = await response.json();

			if (json.errors) {
				for (let i = 0; i < json.errors.length; i++) {
					const {data} = json.errors[i];

					if (data && data.length > 0) {
						for (let j = 0; j < data.length; j++) {
							addToast({
								message: json.errors[i].message,
								description: data[j]
							});
						}
					} else {
						addToast({
							message: json.errors[i].message,
							description: ""
						});
					}
				}
				dispatch({
					type: types.SET_LOADING
				});
			} else {
				dispatch({
					type: types.SET_JOBS,
					payload: json.data.getJobs
				});
			}
		}
	}

	// Remove pmj job
	async function removeJob(jobId) {
		dispatch({
			type: types.SET_LOADING
		});

		const response = await fetch(process.env.REACT_APP_endPoint + '/graphql', {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				'Authorization': 'Bearer ' + token
			},
			body: JSON.stringify({
				query: `
					mutation removeJob ($jobId: ID!){
						removeJob (jobId:$jobId) 
					}
				`,
				variables: {
					jobId
				}
			})
		});
		if (response.status === 401) {
			setToken(null);
		}
		else {
			const json = await response.json();

			if (json.errors) {
				for (let i = 0; i < json.errors.length; i++) {
					const {data} = json.errors[i];

					if (data && data.length > 0) {
						for (let j = 0; j < data.length; j++) {
							addToast({
								message: json.errors[i].message,
								description: data[j]
							});
						}
					} else {
						addToast({
							message: json.errors[i].message,
							description: ""
						});
					}
				}
				dispatch({
					type: types.SET_LOADING
				});
			} else {
				dispatch({
					type: types.SET_JOBS,
					payload: [...state.jobs.filter(value => value.id !== jobId)]
				});
			}
		}
	}

	// Add pmj job
	async function createJob(job) {
		dispatch({
			type: types.SET_LOADING
		});

		const response = await fetch(process.env.REACT_APP_endPoint + '/graphql', {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				'Authorization': 'Bearer ' + token
			},
			body: JSON.stringify({
				query: `mutation createJob($job: CreateJobInput!) {
						createJob(job: $job) {
							id
							name
							price
							invoiced
							freelancer {
								id
								fullName
							}
							description
						}
					}`,
				variables: {
					job: {
						...job,
						price: parseFloat(job.price)
					}
				}
			})
		});
		if (response.status === 401) {
			setToken(null);
		}
		else {
			const json = await response.json();

			if (json.errors) {
				for (let i = 0; i < json.errors.length; i++) {
					const {data} = json.errors[i];

					if (data && data.length > 0) {
						for (let j = 0; j < data.length; j++) {
							addToast({
								message: json.errors[i].message,
								description: data[j]
							});
						}
					} else {
						addToast({
							message: json.errors[i].message,
							description: ""
						});
					}
				}

				dispatch({
					type: types.SET_LOADING
				});
			} else {
				dispatch({
					type: types.SET_JOBS,
					payload: [...state.jobs, json.data.createJob]
				});
			}
		}
	}

	return (
		<context.Provider value={{
			...state,
			getJobs,
			createJob,
			removeJob
		}}>
			{children}
		</context.Provider>
	);
};