import PageDiv from "../../PageDiv";
import Languages from "./Languages";
import AddLanguage from "./AddLanguage";
import State from "../../../contexts/language/LanguageState";

function LanguagesPage() {
	return (
		<PageDiv>
			<State>
				<AddLanguage />
				<hr />
				<Languages />
			</State>
		</PageDiv>
	);
}

export default LanguagesPage;