import {useState} from "react";
import {NavLink, useNavigate} from "react-router-dom";
import Error from "../../components/alerts/Error";
import useQueryParams from "../../hooks/useQueryParams";
import SmallSpinner from "../SmallSpinner";

export default function ResetPassword() {
    const [password, setPassword] = useState("");
    const [confirmedPassword, setConfirmedPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const searchParams = useQueryParams();

    const navigate = useNavigate();

    async function onSubmit(evt) {
        evt.preventDefault();

        try {
            if (password !== confirmedPassword) {
                return setError("Make sure you confirm your password.");
            }
            setLoading(true);
            setError(null);

            const response = await fetch(process.env.REACT_APP_endPoint + "/v1/auth/resetPassword", {
                method: "POST",
                body: JSON.stringify({
                    password,
                    token: searchParams.get('token')
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.status === 204) {
                navigate('/signIn');
            }
            else {
                const {error} = await response.json();
                if (error) {
                    setError(error);
                }
            }
        }
        catch (e) {
            console.error(e);
            setError("Failed to reach server.");
        }
        finally {
            setLoading(false);
        }
    }

    return (
        <form onSubmit={onSubmit}>
            <h2>Reset password</h2>
            {error && <Error message={error} />}

            <div className="my-3">
                <label htmlFor="password" className={"form-label"}>New password:</label>
                <input type="password" id={"password"} className={"form-control"} required={true} value={password} onChange={(evt) => {
                    setPassword(evt.target.value);
                }}/>
            </div>
            <div className="my-3">
                <label htmlFor="confirmedPassword" className={"form-label"}>Confirm new password:</label>
                <input type="password" id={"confirmedPassword"} className={"form-control"} required={true} value={confirmedPassword} onChange={(evt) => {
                    setConfirmedPassword(evt.target.value);
                }}/>
            </div>

            <NavLink to={'/signIn'}>Sign in</NavLink>

            <button type={"submit"} className={"btn btn-success w-100 mt-2"} disabled={loading}>Reset password {loading && <SmallSpinner/>}</button>
        </form>
    );
}
