import SortableHeader from "../../SortableHeader";
import CustomerTaskRow from "./CustomerTaskRow";
import {useContext, useEffect} from "react";
import resize from "../../../util/resize";
import Pagination from "../../Pagination";
import customerTaskContext from "../../../contexts/customerTask/customerTaskContext";
import Spinner from "../../Spinner";
import customerTaskTypes from "../../../contexts/customerTask/customerTaskTypes";

function CustomerTasks() {
	const {customerTasks, pageInfo, loading, dispatch} = useContext(customerTaskContext);

	useEffect(() => {
		const items = [];

		try {
			resize(items);
		}
		catch (e) {
			console.error(e);
		}

		return () => {
			for (let item of items) {
				item.element.removeEventListener("mousedown", item.handler);
			}
		}
	}, []);

	function setLimit(newLimit) {
		dispatch({
			type: customerTaskTypes.SET_PAGE_INFO,
			payload: {
				...pageInfo,
				limit: parseInt(newLimit)
			}
		});
	}

	function setOffset(newOffset) {
		dispatch({
			type: customerTaskTypes.SET_PAGE_INFO,
			payload: {
				...pageInfo,
				offset: parseInt(newOffset)
			}
		});
	}

	return (
		<div className={"table-responsive overflow-auto"}>
			<Pagination limit={pageInfo.limit} offset={pageInfo.offset} hasNextPage={pageInfo.hasNextPage} hasPreviousPage={pageInfo.hasPreviousPage} setLimit={setLimit} setOffset={setOffset} maxOffset={pageInfo.maxOffset}/>
			<table className={"table table-striped table-bordered mt-2"} id={"resizeMe"}>
				<thead className={"table-light"}>
				<tr>
					<th>Customer</th>
					<SortableHeader text={"Code"} columnName={"customerTasks.code"}/>
					<SortableHeader text={"Name"} columnName={"customerTasks.name"}/>
					<SortableHeader text={"Description"} columnName={"customerTasks.description"} />
					<th>Comments</th>
					<th>External costs</th>
					<th>Internal costs</th>
					<th>Estimated bonus</th>
					<SortableHeader text={"Estimated invoice value"} columnName={"customerTasks.estimatedInvoiceValue"} />
					<SortableHeader text={"Expected due date"} columnName={"customerTasks.expectedDueDate"} />
					<th>PO number</th>
					<SortableHeader text={"Invoice amount"} columnName={"customerTasks.invoiceAmount"} />
					<SortableHeader text={"Final amount"} columnName={"customerTasks.finalAmount"} />
					<SortableHeader text={"Invoice date"} columnName={"customerTasks.invoiceDate"} />
					<th>Invoice number</th>
					<th>Invoiced</th>
					<th>Actions</th>
				</tr>
				</thead>
				<tbody>
					{loading && <tr><td colSpan={17}><Spinner /></td></tr>}
						{customerTasks.map(value => {
							return <CustomerTaskRow customerTask={value} key={value.id}/>;
						})
					}
				</tbody>
			</table>
			<Pagination limit={pageInfo.limit} offset={pageInfo.offset} hasNextPage={pageInfo.hasNextPage} hasPreviousPage={pageInfo.hasPreviousPage} setLimit={setLimit} setOffset={setOffset} maxOffset={pageInfo.maxOffset}/>
		</div>
	);
}

export default CustomerTasks;