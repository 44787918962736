import {useContext, useEffect, useRef, useState} from "react";
import Spinner from "../../Spinner";
import customerContext from "../../../contexts/customers/customerContext";
import Error from "../../alerts/Error";

function ArchiveCustomer ({customer}) {
	const {loading, archiveCustomer} = useContext(customerContext);
	const [error, setError] = useState(null);

	useEffect(() => {
		return () => {
			const backdrops = document.getElementsByClassName("modal-backdrop fade show");
			for (let i = 0; i < backdrops.length; i++) {
				backdrops[i].remove();
			}
		}
	}, []);

	async function onSubmit(evt) {
		evt.preventDefault();
		setError(null);
		try {
			await archiveCustomer(customer.id);
		}
		catch (e) {
			console.error(e);
			setError(e.message);
		}
	}

	return (
		<>
			<button className={"btn btn-warning btn-sm"} data-bs-toggle={"modal"} data-bs-target={"#archiveCustomerModal" + customer.id}>Archive customer</button>
			<div className={"modal fade"} id={"archiveCustomerModal" + customer.id} tabIndex="-1" aria-labelledby={"archiveCustomerModalLabel" + customer.id} aria-hidden="true">
				<div className={"modal-dialog"}>
					<div className={"modal-content"}>
						<form onSubmit={onSubmit}>
							<div className={"modal-header"}>
								<h5 className={"modal-title"} id={"archiveCustomerModalLabel" + customer.id}>Archive customer</h5>
								<button type={"button"} className={"btn-close"} data-bs-dismiss={"modal"} />
							</div>
							<div className={"modal-body"}>
								{error && <Error message={error} /> }
								<span>Are you sure you want to archive {customer.fullName}?</span>
								<p>Archived customers won't be seen in filters, project and customer-task creation.</p>
							</div>
							<div className={"modal-footer justify-content-between"}>
								<button type={"button"} className={"btn btn-secondary"} data-bs-dismiss={"modal"}>Close</button>
								<button type={"submit"} className={"btn btn-warning"} disabled={loading}>{loading ? <Spinner />: 'Archive customer'}</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	)
}

export default ArchiveCustomer;
