import {useContext, useState} from "react";
import Spinner from "../../Spinner";
import customerContext from "../../../contexts/customers/customerContext";
import Error from "../../alerts/Error";

const initialCustomer = {
	firstName: '',
	lastName: '',
	description: ''
};

function AddCustomer() {
	const [customer, setCustomer] = useState(initialCustomer);
	const [error, setError] = useState(null);

	const {createCustomer, loading} = useContext(customerContext);

	async function onSubmit(evt) {
		evt.preventDefault();
		setError(null);

		try {
			await createCustomer(customer);
		}
		catch (e) {
			console.error(e);
			setError(e.message);
		}
		setCustomer(initialCustomer);
	}

	function onChange(evt) {
		switch (evt.target.type) {
			case "checkbox":
				setCustomer({
					...customer,
					[evt.target.id]: evt.target.checked
				});
				break;
			default:
				setCustomer({
					...customer,
					[evt.target.id]: evt.target.value
				});
		}
	}

	return (
		<div>
			<button className={"btn btn-success"} data-bs-toggle={"modal"} data-bs-target={"#addCustomerModal"}>Add customer</button>
			<div className={"modal fade"} id={"addCustomerModal"} tabIndex="-1" aria-labelledby="addCustomerModalLabel" aria-hidden="true">
				<div className={"modal-dialog modal-lg"}>
					<div className={"modal-content"}>
						<form onSubmit={onSubmit}>
							<div className={"modal-header"}>
								<h5 className={"modal-title"} id={"addCustomerModalLabel"}>Add customer</h5>
								<button type={"button"} className={"btn-close"} data-bs-dismiss={"modal"} />
							</div>
							<div className={"modal-body"}>
								{error && <Error message={error} /> }
								<div className="row mb-3">
									<div className={"col"}>
										<label htmlFor="firstName" className={"form-label"}>First name:</label>
										<input type="text" id={"firstName"} value={customer.firstName} className={"form-control"} placeholder={"William"} required={true} onChange={onChange}/>
									</div>
									<div className={"col"}>
										<label htmlFor="lastName" className={"form-label"}>Last name:</label>
										<input type="text" id={"lastName"} value={customer.lastName} className={"form-control"} placeholder={"Wong"} required={true} onChange={onChange}/>
									</div>
								</div>

								<div className={"mb-3"}>
									<label htmlFor={"description"} className={"form-label"}>Description:</label>
									<textarea id={"description"} className={"form-control"} name={"description"} value={customer.description || ''} placeholder={"blabla"} onChange={onChange}/>
								</div>
							</div>
							<div className={"modal-footer justify-content-between"}>
								<button type={"button"} className={"btn btn-secondary"} data-bs-dismiss={"modal"}>Close</button>
								<button type={"submit"} className={"btn btn-success"} disabled={loading}>{loading ? <Spinner />: 'Add customer'}</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AddCustomer;
