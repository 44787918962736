import EditLanguagePair from "./EditLanguagePair";
import RemoveLanguagePair from "./RemoveLanguagePair";

function LanguagePairRow({languagePair}) {
	return (
		<tr>
			<td>{languagePair.sourceLanguage}</td>
			<td>{languagePair.targetLanguage}</td>
			<td>{languagePair.group}</td>
			<td>
				<div className={"d-flex gap-2"}>
					<EditLanguagePair languagePair={languagePair} />
					<RemoveLanguagePair languagePair={languagePair}/>
				</div>
			</td>
		</tr>
	);
}

export default LanguagePairRow;