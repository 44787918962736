import {useContext} from "react";
import purchaseOrderContext from "../../../contexts/purchaseOrders/purchaseOrderContext";
import purchaseOrderTypes from "../../../contexts/purchaseOrders/purchaseOrderTypes";

function PurchaseOrderFilters() {
	const {dispatch, filters} = useContext(purchaseOrderContext);

	return (
		<div>
			<div className={"d-flex gap-3"}>
				<select className={"form-select"} value={filters.consumed} onChange={(evt) => {
					dispatch({
						type: purchaseOrderTypes.SET_FILTERS,
						payload: {
							...filters,
							consumed: evt.target.value
						}
					});
				}}>
					<option value="0">All</option>
					<option value="1">Available</option>
					<option value="2">Consumed</option>
				</select>
			</div>
		</div>
	);
}

export default PurchaseOrderFilters;
