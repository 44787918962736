import {useContext, useEffect, useRef, useState} from "react";
import Spinner from "../../Spinner";
import languagePairContext from "../../../contexts/languagePair/languagePairContext";
import Error from "../../alerts/Error";

function RemoveLanguagePair ({languagePair, dispatch}) {
	const {loading, removeLanguagePair} = useContext(languagePairContext);
	const [error, setError] = useState(null);

	useEffect(() => {
		return () => {
			const backdrops = document.getElementsByClassName("modal-backdrop fade show");
			for (let i = 0; i < backdrops.length; i++) {
				backdrops[i].remove();
			}
		}
	}, []);

	async function onSubmit(evt) {
		evt.preventDefault();
		setError(null);

		try {
			await removeLanguagePair(languagePair.id);
		}
		catch (e) {
			console.error(e);
			setError(e.message);
		}
	}

	return (
		<>
			<button className={"btn btn-danger btn-sm"} data-bs-toggle={"modal"} data-bs-target={"#removeLanguagePairModal" + languagePair.id}>Remove language pair</button>
			<div className={"modal fade"} id={"removeLanguagePairModal" + languagePair.id} tabIndex="-1" aria-labelledby={"removeLanguagePairModalLabel" + languagePair.id} aria-hidden="true">
				<div className={"modal-dialog"}>
					<div className={"modal-content"}>
						<form onSubmit={onSubmit}>
							<div className={"modal-header"}>
								<h5 className={"modal-title"} id={"removeLanguagePairModalLabel" + languagePair.id}>Remove language pair</h5>
								<button type={"button"} className={"btn-close"} data-bs-dismiss={"modal"} />
							</div>
							<div className={"modal-body"}>
								{error && <Error message={error} /> }
								<span>Are you sure you want to remove {languagePair.sourceLanguage} --> {languagePair.targetLanguage}?</span>
							</div>
							<div className={"modal-footer justify-content-between"}>
								<button type={"button"} className={"btn btn-secondary"} data-bs-dismiss={"modal"}>Close</button>
								<button type={"submit"} className={"btn btn-danger"}>{loading ? <Spinner />: 'Remove language pair'}</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	)
}

export default RemoveLanguagePair;
