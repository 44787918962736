import {useContext, useEffect, useState} from "react";
import Spinner from "../../Spinner";
import languagePairContext from "../../../contexts/languagePair/languagePairContext";
import languagePairGroupContext from "../../../contexts/languagePairGroup/languagePairGroupContext";
import Error from "../../alerts/Error";

function EditLanguagePair ({languagePair: initialLanguagePair}) {
	const [languagePairGroupId, setLanguagePairGroupId] = useState(initialLanguagePair.languagePairGroupId);

	const {editLanguagePair, loading} = useContext(languagePairContext);
	const {languagePairGroups} = useContext(languagePairGroupContext);
	const [error, setError] = useState(null);

	useEffect(() => {
		return () => {
			const backdrops = document.getElementsByClassName("modal-backdrop fade show");
			for (let i = 0; i < backdrops.length; i++) {
				backdrops[i].remove();
			}
		}
	}, []);

	async function onSubmit(evt) {
		evt.preventDefault();
		setError(null);

		try {
			await editLanguagePair(initialLanguagePair.id, {
				languagePairGroupId
			});
		}
		catch (e) {
			console.error(e);
			setError(e.message);
		}
	}

	return (
		<>
			<button className={"btn btn-warning btn-sm"} data-bs-toggle={"modal"} data-bs-target={"#editLanguagePairModal" + initialLanguagePair.id}>Edit language pair</button>
			<div className={"modal fade"} id={"editLanguagePairModal" + initialLanguagePair.id} tabIndex="-1" aria-labelledby={"editLanguagePairModalLabel" + initialLanguagePair.id} aria-hidden="true">
				<div className={"modal-dialog modal-lg"}>
					<div className={"modal-content"}>
						<form onSubmit={onSubmit}>
							<div className={"modal-header"}>
								<h5 className={"modal-title"} id={"editLanguagePairModalLabel" + initialLanguagePair.id}>Edit {initialLanguagePair.sourceLanguage} --&gt; {initialLanguagePair.targetLanguage}</h5>
								<button type={"button"} className={"btn-close"} data-bs-dismiss={"modal"} />
							</div>
							<div className={"modal-body"}>
								{error && <Error message={error} /> }

								<div className={"mb-3"}>
									<label htmlFor="languagePairGroupId" className={"form-label"}>Group:</label>
									<select id="languagePairGroupId" className={"form-select"} required={true} value={languagePairGroupId} onChange={(evt) => {
										setLanguagePairGroupId(evt.target.value);
									}}>
										<option value="">Select an option</option>
										{languagePairGroups.map(value => {
											return <option key={value.id} value={value.id}>{value.name}</option>;
										})}
									</select>
								</div>
							</div>
							<div className={"modal-footer justify-content-between"}>
								<button type={"button"} className={"btn btn-secondary"} data-bs-dismiss={"modal"}>Close</button>
								<button type={"submit"} className={"btn btn-warning"}>{loading ? <Spinner />: 'Save'}</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	)
}

export default EditLanguagePair;
