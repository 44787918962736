import {useContext, useEffect, useRef, useState} from "react";
import Spinner from "../../Spinner";
import languageContext from "../../../contexts/language/languageContext";
import Error from "../../alerts/Error";

function RemoveLanguage ({language}) {
	const {loading, removeLanguage} = useContext(languageContext);
	const [error, setError] = useState(null);


	useEffect(() => {
		return () => {
			const backdrops = document.getElementsByClassName("modal-backdrop fade show");
			for (let i = 0; i < backdrops.length; i++) {
				backdrops[i].remove();
			}
		}
	}, []);

	async function onSubmit(evt) {
		evt.preventDefault();
		setError(null);

		try {
			await removeLanguage(language.id);
		}
		catch (e) {
			console.error(e);
			setError(e.message);
		}
	}

	return (
		<>
			<button className={"btn btn-danger btn-sm"} data-bs-toggle={"modal"} data-bs-target={"#removeLanguageModal" + language.id}>Remove language</button>
			<div className={"modal fade"} id={"removeLanguageModal" + language.id} tabIndex="-1" aria-labelledby={"removeLanguageModalLabel" + language.id} aria-hidden="true">
				<div  className={"modal-dialog"}>
					<div className={"modal-content"}>
						<form onSubmit={onSubmit}>
							<div className={"modal-header"}>
								<h5 className={"modal-title"} id={"removeLanguageModalLabel" + language.id}>Remove language</h5>
								<button type={"button"} className={"btn-close"} data-bs-dismiss={"modal"} />
							</div>
							<div className={"modal-body"}>
								{error && <Error message={error} /> }
								<span>Are you sure you want to remove {language.name}?</span>
							</div>
							<div className={"modal-footer justify-content-between"}>
								<button type={"button"} className={"btn btn-secondary"} data-bs-dismiss={"modal"}>Close</button>
								<button type={"submit"} className={"btn btn-danger"}>{loading ? <Spinner />: 'Remove language'}</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	)
}

export default RemoveLanguage;
