export default function (items) {
	const createResizableColumn = function (col, resizer) {
		// Track the current position of mouse
		let x = 0;
		let w = 0;

		const mouseDownHandler = function (e) {
			// Get the current mouse position
			x = e.clientX;

			// Calculate the current width of column
			const styles = window.getComputedStyle(col);
			w = parseInt(styles.width, 10);

			// Attach listeners for document's events
			document.addEventListener('mousemove', mouseMoveHandler);
			document.addEventListener('mouseup', mouseUpHandler);
		};

		const mouseMoveHandler = function (e) {
			// Determine how far the mouse has been moved
			const dx = e.clientX - x;

			// Update the width of column
			col.style.width = `${w + dx}px`;
			resizer.classList.add('resizing');
		};

		// When user releases the mouse, remove the existing event listeners
		const mouseUpHandler = function () {
			document.removeEventListener('mousemove', mouseMoveHandler);
			document.removeEventListener('mouseup', mouseUpHandler);

			resizer.classList.remove('resizing');
		};

		resizer.addEventListener('mousedown', mouseDownHandler);
		return {element: resizer, handler: mouseDownHandler};
	};
	const table = document.getElementById('resizeMe');
	const cols = table.querySelectorAll('th');
	[].forEach.call(cols, function (col) {
		const resizer = document.createElement('div');
		resizer.classList.add('resizer');
		resizer.style.height = `${table.offsetHeight}px`;
		col.appendChild(resizer);
		items.push(createResizableColumn(col, resizer));
	});
}