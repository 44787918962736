import {useContext, useEffect, useState} from "react";
import Spinner from "../../Spinner";
import workTypeContext from "../../../contexts/workTypes/workTypeContext";
import unitNameContext from "../../../contexts/unitName/unitNameContext";
import Error from "../../alerts/Error";

function EditWorkType ({workType: initialWorkType}) {
	const [workType, setWorkType] = useState(initialWorkType);
	const {loading, editWorkType} = useContext(workTypeContext);
	const {unitNames} = useContext(unitNameContext);
	const [error, setError] = useState(null);

	useEffect(() => {
		return () => {
			const backdrops = document.getElementsByClassName("modal-backdrop fade show");
			for (let i = 0; i < backdrops.length; i++) {
				backdrops[i].remove();
			}
		}
	}, []);

	async function onSubmit(evt) {
		evt.preventDefault();
		setError(null);

		try {
			await editWorkType(initialWorkType.id, workType);
		}
		catch (e) {
			console.error(e);
			setError(e.message);
		}
	}

	function onChange(evt) {
		switch (evt.target.type) {
			case "checkbox":
				setWorkType({
					...workType,
					[evt.target.name]: evt.target.checked
				});
				break;
			default:
				setWorkType({
					...workType,
					[evt.target.name]: evt.target.value
				});
		}
	}

	return (
		<>
			<button className={"btn btn-warning btn-sm"} data-bs-toggle={"modal"} data-bs-target={"#editWorkTypeModal" + workType.id}>Edit work type</button>
			<div className={"modal fade"} id={"editWorkTypeModal" + workType.id} tabIndex="-1" aria-labelledby={"editWorkTypeModalLabel" + workType.id} aria-hidden="true">
				<div className={"modal-dialog modal-lg"}>
					<div className={"modal-content"}>
						<form onSubmit={onSubmit}>
							<div className={"modal-header"}>
								<h5 className={"modal-title"} id={"editWorkTypeModalLabel" + workType.id}>Edit work type</h5>
								<button type={"button"} className={"btn-close"} data-bs-dismiss={"modal"} />
							</div>
							<div className={"modal-body"}>
								{error && <Error message={error} /> }

								<div className="mb-3">
									<label htmlFor={"unitName" + workType.id} className={"form-label"}>Unit name:</label>
									<select className={"form-select"} name={"unitNameId"} id={"unitName" + workType.id} value={workType.unitNameId} onChange={onChange} required={true}>
										{unitNames.map(value => {
											return <option value={value.id} key={value.id}>{value.name}</option>;
										})}
									</select>
								</div>

								<div className="row mb-3">
									<div className={"col"}>
										<label htmlFor={"unitValue" + workType.id} className={"form-label"}>Unit value:</label>
										<input type="number" id={"unitValue" + workType.id} className={"form-control"} name={"unitValue"} value={workType.unitValue} required={true} onChange={onChange}/>
									</div>
									<div className={"col"}>
										<label htmlFor={"minUnitValue" + workType.id} className={"form-label"}>Unit value (min):</label>
										<input type="number" id={"minUnitValue" + workType.id} className={"form-control"} name={"minUnitValue"} value={workType.minUnitValue} required={true} onChange={onChange}/>
									</div>
									<div className={"col"}>
										<label htmlFor={"maxUnitValue" + workType.id} className={"form-label"}>Unit value (max):</label>
										<input type="number" id={"maxUnitValue" + workType.id} className={"form-control"} name={"maxUnitValue"} value={workType.maxUnitValue} required={true} onChange={onChange}/>
									</div>
								</div>

								<div className="row mb-3">
									<div className={"col"}>
										<label htmlFor={"groupA" + workType.id} className={"form-label"}>Group A:</label>
										<input type="number" id={"groupA" + workType.id} className={"form-control"} name={"groupA"} value={workType.groupA} required={true} onChange={onChange}/>
									</div>
									<div className={"col"}>
										<label htmlFor={"groupB" + workType.id} className={"form-label"}>Group B:</label>
										<input type="number" id={"groupB" + workType.id} className={"form-control"} name={"groupB"} value={workType.groupB} required={true} onChange={onChange}/>
									</div>
									<div className={"col"}>
										<label htmlFor={"groupC" + workType.id} className={"form-label"}>Group C:</label>
										<input type="number" id={"groupC" + workType.id} className={"form-control"} name={"groupC"} value={workType.groupC} required={true} onChange={onChange}/>
									</div>
								</div>

								<div className={"mb-3"}>
									<label htmlFor={"description" + workType.id} className={"form-label"}>Description:</label>
									<textarea id={"description" + workType.id} className={"form-control"} name={"description"} value={workType.description || ''} placeholder={"blabla"} onChange={onChange}/>
								</div>
							</div>
							<div className={"modal-footer justify-content-between"}>
								<button type={"button"} className={"btn btn-secondary"} data-bs-dismiss={"modal"}>Close</button>
								<button type={"submit"} className={"btn btn-warning"} disabled={loading}>{loading ? <Spinner />: 'Save'}</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
}

export default EditWorkType;
