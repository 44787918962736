import React, {useState} from "react";
import Success from "../alerts/Success";
import Error from "../alerts/Error";
import {NavLink} from "react-router-dom";
import SmallSpinner from "../SmallSpinner";

export default function ForgotPassword() {
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    async function onSubmit(evt) {
        evt.preventDefault();

        try {
            setLoading(true);
            setError(null);

            const response = await fetch(process.env.REACT_APP_endPoint + "/v1/auth/forgotPassword", {
                method: "POST",
                body: JSON.stringify({
                    email
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.status === 201) {
                setSuccess("A reset password link has been sent to your email address.");
            }
            else {
                const {error} = await response.json();
                if (error) {
                    setError(error);
                }
            }
        }
        catch (e) {
            console.error(e);
            setError("Failed to reach server.");
        }
        finally {
            setLoading(false);
        }
    }

    return (
        <form onSubmit={onSubmit} >
            <h2>Forgot password</h2>

            {error && <Error message={error} />}
            {success && <Success message={success} />}
            <div className={"my-3"}>
                <label htmlFor="email" className={"form-label"}>Email address:</label>
                <input type="email" id={"email"} className={"form-control"} required={true} value={email} onChange={evt => {
                    setEmail(evt.target.value);
                }}/>
            </div>

            <NavLink to={'/signIn'}>Sign in</NavLink>

            <button type={"submit"} className={"btn btn-success w-100 mt-2"} disabled={loading}>I forgot my password {loading && <SmallSpinner />}</button>
        </form>
    );
}
