import {useContext} from "react";
import customerTaskContext from "../../../contexts/customerTask/customerTaskContext";
import customerTaskTypes from "../../../contexts/customerTask/customerTaskTypes";
import customerContext from "../../../contexts/customers/customerContext";

function CustomerTaskFilters() {
	const {dispatch, filters} = useContext(customerTaskContext);
	const {customers} = useContext(customerContext);

	return (
		<div>
			<div className={"d-flex gap-3"}>
				<select className={"form-select"} value={filters.invoiced} onChange={(evt) => {
					dispatch({
						type: customerTaskTypes.SET_FILTERS,
						payload: {
							...filters,
							invoiced: evt.target.value
						}
					});
				}}>
					<option value="0">Not invoiced</option>
					<option value="1">Invoiced</option>
				</select>
				<select className={"form-select"} multiple={true} value={filters.customerId} onChange={(evt) => {
					dispatch({
						type: customerTaskTypes.SET_FILTERS,
						payload: {
							...filters,
							customerId: Array.from(evt.target.selectedOptions, option => option.value)
						}
					});
				}}>
					{customers.map(value => {
						return <option key={value.id} value={value.id}>{value.fullName}</option>;
					})}
				</select>
				<select name="status" id="status" className="form-select" multiple={true} value={filters.status} onChange={(evt) => {
					dispatch({
						type: customerTaskTypes.SET_FILTERS,
						payload: {
							...filters,
							status: Array.from(evt.target.selectedOptions, option => option.value)
						}
					});
				}}>
					<option value="Quote Analysis">Quote Analysis</option>
					<option value="Quote Accepted">Quote Accepted</option>
					<option value="Quote Rejected">Quote Rejected</option>
					<option value="In progress">In progress</option>
					<option value="On Hold">On Hold</option>
					<option value="Delivered"> Delivered</option>
					<option value="Closed">Closed</option>
				</select>
			</div>

			<input type="text" className={"form-control mt-3"} placeholder={"Search code, name & description"} value={filters.search} onChange={(evt) => {
				dispatch({
					type: customerTaskTypes.SET_FILTERS,
					payload: {
						...filters,
						search: evt.target.value
					}
				});
			}}/>
		</div>
	);
}

export default CustomerTaskFilters;