import SortableHeader from "../../SortableHeader";
import LanguagePairRow from "./LanguagePairRow";
import {useContext, useEffect, useState} from "react";
import resize from "../../../util/resize";
import Pagination from "../../Pagination";
import languagePairContext from "../../../contexts/languagePair/languagePairContext";
import Spinner from "../../Spinner";
import CustomerRow from "../customers/CustomerRow";

function LanguagePairs() {
	const {languagePairs, loading} = useContext(languagePairContext);


	useEffect(() => {
		const items = [];

		try {
			resize(items);
		}
		catch (e) {
			console.error(e);
		}

		return () => {
			for (let item of items) {
				item.element.removeEventListener("mousedown", item.handler);
			}
		}
	}, []);

	return (
		<div className={"table-responsive overflow-auto"}>
			<table className={"table table-striped table-bordered mt-2"} id={"resizeMe"}>
				<thead className={"table-light"}>
				<tr>
					<th>Source language</th>
					<th>Target language {loading}</th>
					<th>Group</th>
					<th>Actions</th>
				</tr>
				</thead>
				<tbody>
				{loading && <tr><td colSpan={4}><Spinner /></td></tr>}
					{languagePairs.map(value => {
						return <LanguagePairRow languagePair={value} key={value.id}/>;
					})
				}
				</tbody>
			</table>
		</div>
	)
}

export default LanguagePairs;