import PageDiv from "../../PageDiv";
import LanguagePairs from "./LanguagePairs";
import AddLanguagePair from "./AddLanguagePair";
import State from "../../../contexts/languagePair/LanguagePairState";
import LanguageState from "../../../contexts/language/LanguageState";
import LanguagePairGroupState from "../../../contexts/languagePairGroup/LanguagePairGroupState";

function LanguagesPage() {

	return (
		<PageDiv>
			<State>
				<LanguagePairGroupState>
					<LanguageState>
						<AddLanguagePair/>
					</LanguageState>

					<hr />
					<LanguagePairs/>
				</LanguagePairGroupState>
			</State>
		</PageDiv>
	);
}

export default LanguagesPage;