import {useState, useRef, useContext, useEffect} from "react";
import customerTaskContext from "../../../contexts/customerTask/customerTaskContext";
import sortContext from "../../../contexts/sort/sortContext";
import SmallSpinner from "../../SmallSpinner";

function ExportCustomerTasks() {
	const [loading, setLoading] = useState(false);
	const abortController = useRef();
	const {filters} = useContext(customerTaskContext);
	const {asc, desc, sortBy} = useContext(sortContext);

	useEffect(() => {
		return () => {
			if (abortController.current) {
				abortController.current.abort();
			}
		}
	}, []);

	async function onSubmit(evt) {
		evt.preventDefault();

		console.log("Exporting...");

		try {
			setLoading(true);
			let params = new URLSearchParams();

			for (let key in filters) {
				params.append(key, filters[key]);
			}
			params.append('asc', asc);
			params.append('desc', desc);
			params.append('column', sortBy)

			if (abortController.current) {
				abortController.current.abort();
			}

			const controller = new AbortController();
			const signal = controller.signal;
			abortController.current = controller;

			const response = await fetch(process.env.REACT_APP_endPoint + '/customerTasks/export?' + params.toString(), {
				method: "GET",
				signal
			});

			let data = [];
			const reader = response.body.getReader();
			reader.read().then(processData);

			function processData({done, value}) {
				try {
					if (done) {
						const uri = URL.createObjectURL(new Blob(data));
						let link = document.createElement("a");
						link.download = "customerTasksExport.tsv";
						link.href = uri;
						link.click();
						link.remove();
						setLoading(false);
					}
					else {
						data.push(value);
						return reader.read().then(processData);
					}
				}
				catch (e) {
					console.error(e);
				}
			}
		}
		catch (e) {
			console.error(e);
			if (e.name === 'AbortError') return     // if the query has been aborted, do nothing
			setLoading(false);
		}
	}

	return (
		<form onSubmit={onSubmit}>
			<button type={"submit"} className={"btn btn-secondary"} disabled={loading}>Export {loading && <SmallSpinner />} customer tasks</button>
		</form>
	);
}

export default ExportCustomerTasks;