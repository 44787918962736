import context from "./customerContext";
import React, {useContext, useEffect, useReducer} from "react";
import reducer from "./customerReducer";
import types from "./customerTypes";
import {NotificationContext} from "../NotificationContext";
import {AuthContext} from "../AuthContext";

export default ({children}) => {
	const initialState = {
		loading: false,
		customers: []
	};

	const [state, dispatch] = useReducer(reducer, initialState);
    const {token, setToken} = useContext(AuthContext);
	const {addToast} = useContext(NotificationContext);

	useEffect(() => {
		const abortController = new AbortController();

		(async () => {
			try {
				await getCustomers(abortController);
			}
			catch (e) {
				console.error(e);
				dispatch({
					type: types.SET_LOADING
				});
			}
		})();

		return () => {
			abortController.abort();
		};
	}, []);

	// Get customers
	async function getCustomers(abortController) {
		dispatch({
			type: types.SET_LOADING
		});

		const response = await fetch(process.env.REACT_APP_endPoint + '/graphql', {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				'Authorization': 'Bearer ' + token
			},
			body: JSON.stringify({
				query: `
					query getCustomers{
						getCustomers{
							id
							fullName
							description
							archived
						}
					}`
			}),
			signal: abortController.signal
		});
		if (response.status === 401) {
			setToken(null);
		}
		else {
			const json = await response.json();

			if (json.errors) {
				for (let i = 0; i < json.errors.length; i++) {
					const {data} = json.errors[i];

					if (data && data.length > 0) {
						for (let j = 0; j < data.length; j++) {
							addToast({
								message: json.errors[i].message,
								description: data[j]
							});
						}
					} else {
						addToast({
							message: json.errors[i].message,
							description: ""
						});
					}
				}
				dispatch({
					type: types.SET_LOADING
				});
			} else {
				dispatch({
					type: types.SET_CUSTOMERS,
					payload: json.data.getCustomers
				});
			}
		}
	}

	// Remove customer
	async function removeCustomer(customerId) {
		dispatch({
			type: types.SET_LOADING
		});

		const response = await fetch(process.env.REACT_APP_endPoint + '/graphql', {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				'Authorization': 'Bearer ' + token
			},
			body: JSON.stringify({
				query: `
					mutation removeCustomer ($customerId: ID!){
						removeCustomer(customerId:$customerId) 
					}
				`,
				variables: {
					customerId
				}
			})
		});
		if (response.status === 401) {
			setToken(null);
		}
		else {
			const json = await response.json();

			if (json.errors) {
				for (let i = 0; i < json.errors.length; i++) {
					const {data} = json.errors[i];

					if (data && data.length > 0) {
						for (let j = 0; j < data.length; j++) {
							addToast({
								message: json.errors[i].message,
								description: data[j]
							});
						}
					} else {
						addToast({
							message: json.errors[i].message,
							description: ""
						});
					}
				}
				dispatch({
					type: types.SET_LOADING
				});
			} else {
				dispatch({
					type: types.SET_CUSTOMERS,
					payload: [...state.customers.filter(value => value.id !== customerId)]
				});
			}
		}
	}

	async function archiveCustomer(customerId) {
		dispatch({
			type: types.SET_LOADING
		});

		const response = await fetch(process.env.REACT_APP_endPoint + '/graphql', {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				'Authorization': 'Bearer ' + token
			},
			body: JSON.stringify({
				query: `
					mutation archiveCustomer ($customerId: ID!){
						archiveCustomer(customerId:$customerId) 
					}
				`,
				variables: {
					customerId
				}
			})
		});
		if (response.status === 401) {
			setToken(null);
		}
		else {
			const json = await response.json();

			if (json.errors) {
				for (let i = 0; i < json.errors.length; i++) {
					const {data} = json.errors[i];

					if (data && data.length > 0) {
						for (let j = 0; j < data.length; j++) {
							addToast({
								message: json.errors[i].message,
								description: data[j]
							});
						}
					} else {
						addToast({
							message: json.errors[i].message,
							description: ""
						});
					}
				}
				dispatch({
					type: types.SET_LOADING
				});
			} else {
				const newCustomers = [...state.customers];
				newCustomers[newCustomers.findIndex(value => value.id === customerId)] = {
					...newCustomers[newCustomers.findIndex(value => value.id === customerId)],
					archived: true
				};
				dispatch({
					type: types.SET_CUSTOMERS,
					payload: newCustomers
				});
			}
		}
	}

	async function unArchiveCustomer(customerId) {
		dispatch({
			type: types.SET_LOADING
		});

		const response = await fetch(process.env.REACT_APP_endPoint + '/graphql', {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				'Authorization': 'Bearer ' + token
			},
			body: JSON.stringify({
				query: `
					mutation unArchiveCustomer ($customerId: ID!){
						unArchiveCustomer(customerId:$customerId) 
					}
				`,
				variables: {
					customerId
				}
			})
		});
		if (response.status === 401) {
			setToken(null);
		}
		else {
			const json = await response.json();

			if (json.errors) {
				for (let i = 0; i < json.errors.length; i++) {
					const {data} = json.errors[i];

					if (data && data.length > 0) {
						for (let j = 0; j < data.length; j++) {
							addToast({
								message: json.errors[i].message,
								description: data[j]
							});
						}
					} else {
						addToast({
							message: json.errors[i].message,
							description: ""
						});
					}
				}
				dispatch({
					type: types.SET_LOADING
				});
			} else {
				const newCustomers = [...state.customers];
				newCustomers[newCustomers.findIndex(value => value.id === customerId)] = {
					...newCustomers[newCustomers.findIndex(value => value.id === customerId)],
					archived: false
				};
				dispatch({
					type: types.SET_CUSTOMERS,
					payload: newCustomers
				});
			}
		}
	}

	// Add customer
	async function createCustomer(customer) {
		dispatch({
			type: types.SET_LOADING
		});

		console.log();

		const response = await fetch(process.env.REACT_APP_endPoint + '/graphql', {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				'Authorization': 'Bearer ' + token
			},
			body: JSON.stringify({
				query: `mutation createCustomer($customer: CreateCustomerInput!) {
						createCustomer(customer: $customer) {
							id
							fullName
							description
							archived
						}
					}`,
				variables: {
					customer: {
						...customer
					}
				}
			})
		});
		if (response.status === 401) {
			setToken(null);
		}
		else {
			const json = await response.json();

			if (json.errors) {
				for (let i = 0; i < json.errors.length; i++) {
					const {data} = json.errors[i];

					if (data && data.length > 0) {
						for (let j = 0; j < data.length; j++) {
							addToast({
								message: json.errors[i].message,
								description: data[j]
							});
						}
					} else {
						addToast({
							message: json.errors[i].message,
							description: ""
						});
					}
				}

				dispatch({
					type: types.SET_LOADING
				});
			} else {
				dispatch({
					type: types.SET_CUSTOMERS,
					payload: [...state.customers, json.data.createCustomer]
				});
			}
		}
	}

	// Edit customer
	async function editCustomer(customerId, customer) {
		dispatch({
			type: types.SET_LOADING
		});

		const response = await fetch(process.env.REACT_APP_endPoint + '/graphql', {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				'Authorization': 'Bearer ' + token
			},
			body: JSON.stringify({
				query: `
					mutation {
						editCustomer(customerId: ${customerId}, customer: {
							description: "${customer.description}"
						}) {
							id
							fullName
							description
							archived
						}
					}
				`
			})
		});
		if (response.status === 401) {
			setToken(null);
		}
		else {
			const json = await response.json();

			if (json.errors) {
				for (let i = 0; i < json.errors.length; i++) {
					const {data} = json.errors[i];

					if (data && data.length > 0) {
						for (let j = 0; j < data.length; j++) {
							addToast({
								message: json.errors[i].message,
								description: data[j]
							});
						}
					} else {
						addToast({
							message: json.errors[i].message,
							description: ""
						});
					}
				}
				dispatch({
					type: types.SET_LOADING
				});
			} else {
				const newCustomers = [...state.customers];
				newCustomers[newCustomers.findIndex(value => value.id === customerId)] = json.data.editCustomer;
				dispatch({
					type: types.SET_CUSTOMERS,
					payload: newCustomers
				});
			}
		}
	}

	return (
		<context.Provider value={{
			...state,
			getCustomers,
			createCustomer,
			editCustomer,
			removeCustomer,
			archiveCustomer,
			unArchiveCustomer
		}}>
			{children}
		</context.Provider>
	);
};
