import {createContext} from "react";

export default createContext({
	customerTasks: [],
	loading: false,
	getCustomerTasks: () => {},
	createCustomerTask: () => {},
	editCustomerTask: () => {},
	removeCustomerTask: () => {},
	getCustomerTask: () => {},
	pageInfo: {
		limit: 10,
		offset: 0,
		maxOffset: 0,
		hasNextPage: false,
		hasPreviousPage: false,
	},
	filters: {
		search: '',
		customerId: [],
		invoiced: false,
		status: []
	},
	dispatch: () => {}
});