import {useContext, useEffect, useState} from "react";
import Spinner from "../../Spinner";
import subCustomerContext from "../../../contexts/subCustomers/subCustomerContext";
import Error from "../../alerts/Error";

function EditSubCustomer ({subCustomer: initialSubCustomer}) {
	const [subCustomer, setSubCustomer] = useState(initialSubCustomer);
	const {loading, editSubCustomer} = useContext(subCustomerContext);
	const [error, setError] = useState(null);

	async function onSubmit(evt) {
		evt.preventDefault();
		setError(null);

		try {
			await editSubCustomer(initialSubCustomer.id, subCustomer);
		}
		catch (e) {
			console.error(e);
			setError(e.message);
		}
	}

	function onChange(evt) {
		switch (evt.target.type) {
			case "checkbox":
				setSubCustomer({
					...subCustomer,
					[evt.target.name]: evt.target.checked
				});
				break;
			default:
				setSubCustomer({
					...subCustomer,
					[evt.target.name]: evt.target.value
				});
		}
	}

	return (
		<>
			<button className={"btn btn-secondary btn-sm"} data-bs-toggle={"modal"} data-bs-target={"#editSubCustomerModal" + subCustomer.id}>Edit sub-customer</button>
			<div className={"modal fade"} id={"editSubCustomerModal" + subCustomer.id} tabIndex="-1" aria-labelledby={"editSubCustomerModalLabel" + subCustomer.id} aria-hidden="true">
				<div className={"modal-dialog modal-lg"}>
					<div className={"modal-content"}>
						<form onSubmit={onSubmit}>
							<div className={"modal-header"}>
								<h5 className={"modal-title"} id={"editSubCustomerModalLabel" + subCustomer.id}>Edit sub-customer</h5>
								<button type={"button"} className={"btn-close"} data-bs-dismiss={"modal"} />
							</div>
							<div className={"modal-body"}>
								{error && <Error message={error} /> }

								<div className="mb-3">
									<label htmlFor={"firstName" + subCustomer.id} className={"form-label"}>First name:</label>
									<input type="text" id={"firstName" + subCustomer.id} value={subCustomer.firstName} className={"form-control"} placeholder={""} required={true} onChange={onChange}/>
									<label htmlFor={"lastName" + subCustomer.id} className={"form-label"}>Last name:</label>
									<input type="text" id={"lastName" + subCustomer.id} value={subCustomer.lastName} className={"form-control"} placeholder={""} required={true} onChange={onChange}/>
								</div>

								<div className={"mb-3"}>
									<label htmlFor={"email" + subCustomer.id} className={"form-label"}>Email:</label>
									<textarea id={"email" + subCustomer.id} className={"form-control"} name={"email"} value={subCustomer.email || ''} placeholder={"blabla"} onChange={onChange}/>
								</div>
								<div className={"mb-3"}>
									<label htmlFor={"rights" + subCustomer.id} className={"form-label"}>Rights:</label>
									<select name="rights" id={"rights" + subCustomer.id} className={"form-select"} required={true} value={subCustomer.rights} onChange={onChange}>
										<option value="">Select an option</option>
										<option value="view">View only</option>;
										<option value="view/export">View/Export</option>;
									</select>
								</div>
							</div>
							<div className={"modal-footer justify-content-between"}>
								<button type={"button"} className={"btn btn-secondary"} data-bs-dismiss={"modal"}>Close</button>
								<button type={"submit"} className={"btn btn-primary"} disabled={loading}>{loading ? <Spinner />: 'Save'}</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
}

export default EditSubCustomer;
