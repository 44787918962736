import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import subCustomerContext from "../../../contexts/subCustomers/subCustomerContext";

function SubCustomersBreadcrums({customerFullName}) {
    return (
        <>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/customers">Customers</Link></li>
                <li className="breadcrumb-item active" aria-current="page">{customerFullName}</li>
            </ol>
        </nav>
        </>

    );
}


export default SubCustomersBreadcrums;
