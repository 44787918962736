import {useContext, useEffect, useRef} from "react";
import {NotificationContext} from "../../contexts/NotificationContext";

function Toast({message, description, id}) {
	const toast = useRef(null);
	const {removeToast} = useContext(NotificationContext);

	useEffect(() => {
		const toastInstance = new window.bootstrap.Toast(toast.current);
		toastInstance.show();

		function eventListener (evt)  {
			removeToast(id);
		}

		toast.current.addEventListener("hidden.bs.toast", eventListener);
	}, []);


	return (
		<div className="toast align-items-center" role="alert" aria-live="assertive" aria-atomic="true" ref={toast} data-bs-autohide="false">
			<div className="d-flex">
				<div className="toast-body">
					<h5 style={{wordBreak: "break-all"}}>{message}</h5>
					{description}
				</div>
				<button type="button" className="btn-close me-2 m-auto" data-bs-dismiss="toast"
						aria-label="Close" />
			</div>
		</div>
	);
}

export default Toast;