import PageDiv from "../../PageDiv";
import {useContext, useEffect, useRef, useState} from "react";
import ActivityFilters from "./ActivityFilters";
import Chart from "chart.js/auto";
import 'chartjs-adapter-moment';

import {NotificationContext} from "../../../contexts/NotificationContext";
import Spinner from "../../Spinner";
import {AuthContext} from "../../../contexts/AuthContext";

function ActivityPage() {
	const today = Date.now();
	const initialFrom = new Date(today - 86400000);
	const initialTo = new Date(today);
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [from, setFrom] = useState(initialFrom.getFullYear() + "-" + (initialFrom.getMonth().toString().length === 1 ? "0" + (initialFrom.getMonth() + 1) : initialFrom.getMonth() + 1) + "-" + (initialFrom.getDate().toString().length === 1 ? "0" + initialFrom.getDate()  : initialFrom.getDate()));
	const [to, setTo] = useState(initialTo.getFullYear() + "-" + (initialTo.getMonth().toString().length === 1 ? "0" + (initialTo.getMonth() + 1) : initialTo.getMonth() + 1) + "-" + (initialTo.getDate().toString().length === 1 ? "0" + initialTo.getDate()  : initialTo.getDate()));
	const [onlineUsers, setOnlineUsers] = useState(0);

	const {addToast} = useContext(NotificationContext);
	const {token} = useContext(AuthContext);

	const myChart = useRef(null);

	useEffect(() => {
		const abortController = new AbortController();

		(async () => {
			try {
				const response = await fetch(process.env.REACT_APP_endPoint + '/graphql', {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						'Authorization': 'Bearer ' + token
					},
					body: JSON.stringify({
						query: `
					query getActivity ($filters:ActivityFilters){
						getActivity (filters:$filters){
							onlineUsers
							stamp
						}
					}`,
						variables: {
							filters: {
								from,
								to
							}
						}
					}),
					signal: abortController.signal
				});
				const json = await response.json();

				if (json.errors) {
					for (let i = 0; i < json.errors.length; i++) {
						const {data} = json.errors[i];

						if (data && data.length > 0) {
							for (let j = 0; j < data.length; j++) {
								addToast({
									message:json.errors[i].message,
									description: data[j]
								});
							}
						}
						else {
							addToast({
								message: json.errors[i].message,
								description: ""
							});
						}
					}
					setLoading(false)
				}
				else {
					const data = json.data.getActivity.map(value => {
						return {
							x: value.stamp,
							y: value.onlineUsers
						};
					});
					setData(data);
				}
			}
			catch (e) {
				console.error(e);
			}
		})();

		return () => {
			abortController.abort();
		}
	}, [from, to]);

	useEffect(() => {
		const abortController = new AbortController();

		(async () => {
			try {
				const response = await fetch(process.env.REACT_APP_endPoint + '/graphql', {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						'Authorization': 'Bearer ' + token
					},
					body: JSON.stringify({
						query: `
					query getOnlineUsers {
						getOnlineUsers
					}`,
						variables: {
							filters: {
								from,
								to
							}
						}
					}),
					signal: abortController.signal
				});
				const json = await response.json();

				if (json.errors) {
					for (let i = 0; i < json.errors.length; i++) {
						const {data} = json.errors[i];

						if (data && data.length > 0) {
							for (let j = 0; j < data.length; j++) {
								addToast({
									message:json.errors[i].message,
									description: data[j]
								});
							}
						}
						else {
							addToast({
								message: json.errors[i].message,
								description: ""
							});
						}
					}
				}
				else {
					setOnlineUsers(json.data.getOnlineUsers);
				}
			}
			catch (e) {
				console.error(e);
			}
		})();

		return () => {
			abortController.abort();
		}
	}, []);

	useEffect(() => {
		const config = {
			type: 'line',
			data: {
				datasets: [{
					label: "# of users online",
					data: data
				}]
			},
			options: {
				maintainAspectRatio: false,
				scales: {
					xAxis: {
						type: 'time'
					}
				}
			}
		};

		const chart = new Chart(myChart.current, config);


		return () => {
			chart && chart.destroy();
		}
	}, [JSON.stringify(data)]);

	return (
		<PageDiv>
			<h5 className={"text-center"}>Online users: {onlineUsers}</h5>
			<hr />
			<ActivityFilters from={from} setFrom={setFrom} to={to} setTo={setTo}/>
			<hr />
			{loading && <Spinner />}
			<div style={{height:"80%"}}>
				<canvas ref={myChart}>

				</canvas>
			</div>
		</PageDiv>
	)
}

export default ActivityPage;