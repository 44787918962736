import {createContext} from "react";

export default createContext({
	subCustomers: [],
	customerId: "",
	loading: false,
	getSubCustomers: () => {},
	createSubCustomer: () => {},
	editSubCustomer: () => {},
	removeSubCustomer: () => {},
	getSubCustomer: () => {},
});
