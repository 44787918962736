
function ActivityFilters({from, to, setFrom, setTo}) {
	return (
		<div>
			<div className={"d-flex gap-3"}>
				<div className={"flex-grow-1"}>
					<label htmlFor="from" className={"form-label"}>From:</label>
					<input type="date" id={"from"} className={"form-control"} value={from} onChange={(evt) => {
						if (!evt.target.value) {
							evt.preventDefault();
						}
						else {
							if (new Date(to) < new Date(evt.target.value)) {

							}
							else {
								setFrom(evt.target.value);
							}
						}
					}}/>
				</div>
				<div className={"flex-grow-1"}>
					<label htmlFor="to" className={"form-label"}>To:</label>
					<input type="date" id={"to"} className={"form-control"} value={to} onChange={(evt) => {
						if (!evt.target.value) {
							evt.preventDefault();
						}
						else {
							if (new Date(from) > new Date(evt.target.value)) {
								evt.preventDefault();
							}
							else {
								setTo(evt.target.value);
							}
						}
					}}/>
				</div>
			</div>
		</div>
	);
}

export default ActivityFilters;