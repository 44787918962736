import React, {createContext} from "react";

export default createContext({
	asc: false,
	desc: true,
	sortBy: 'id',
	setAsc: () => {},
	setDesc: () => {},
	setSortBy: () => {}
});
