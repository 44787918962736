import PageDiv from "../../PageDiv";
import Customers from "./Customers";
import AddCustomer from "./AddCustomer";
import State from "../../../contexts/customers/CustomerState";

function CustomersPage() {
	return (
		<PageDiv>
			<State>
				<AddCustomer />
				<hr />
				<Customers />
			</State>
		</PageDiv>
	)
}

export default CustomersPage;