import SubCustomerRow from "./SubCustomerRow";
import {useContext, useEffect} from "react";
import resize from "../../../util/resize";
import subCustomerContext from "../../../contexts/subCustomers/subCustomerContext";
import Spinner from "../../Spinner";

function SubCustomers() {
	const {subCustomers, loading} = useContext(subCustomerContext);

	return (
		<div className={"table-responsive overflow-auto"}>
			<table className={"table table-striped table-bordered mt-2"} id={"resizeMe"}>
				<thead className={"table-light"}>
				<tr>
					<th>First Name</th>
					<th>Last Name</th>
					<th>Email</th>
					<th>Rights</th>
				</tr>
				</thead>
				<tbody>
				{loading && <tr><td colSpan={6}><Spinner /></td></tr>}
					{subCustomers.map(value => {
						return <SubCustomerRow subCustomer={value} key={`subcustomer${value.id}`}/>;
					})
				}
				</tbody>
			</table>
		</div>
	)
}

export default SubCustomers;
