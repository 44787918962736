import SortState from "../../../contexts/sort/SortState";
import PurchaseOrderState from "../../../contexts/purchaseOrders/purchaseOrderState";
import CustomerState from "../../../contexts/customers/CustomerState";
import PageDiv from "../../PageDiv";
import AddPurchaseOrder from "./AddPurchaseOrder";
import PurchaseOrders from "./PurchaseOrders";
import PurchaseOrderFilters from "./PurchaseOrderFilters";

function PurchaseOrdersPage() {
	return (
		<PageDiv>
			<SortState initialColumn={'purchaseOrders.id'}>
				<PurchaseOrderState>
					<CustomerState>
						<div className="d-flex gap-2">
							<AddPurchaseOrder />
						</div>
						<hr />
						<PurchaseOrderFilters />

						<hr />
						<PurchaseOrders />
					</CustomerState>
				</PurchaseOrderState>
			</SortState>
		</PageDiv>
	)
}

export default PurchaseOrdersPage;
