import {createContext} from "react";

export default createContext({
	pms: [],
	loading: false,
	getPms: () => {},
	createPm: () => {},
	editPm: () => {},
	removePm: () => {},
	unblockPm: () => {}
});