import EditPm from "./EditPm";
import RemovePm from "./RemovePm";
import UnblockPm from "./UnblockPm";

function PmRow({pm}) {
	return (
		<tr>
			<td>{pm.fullName}</td>
			<td>{pm.email}</td>
			<td>{pm.pin}</td>
			<td>
				<div className={"d-flex gap-2"}>
					<EditPm pm={pm}/>
					<RemovePm pm={pm}/>
					{pm.blocked && <UnblockPm pm={pm} />}
				</div>
			</td>
		</tr>
	);
}

export default PmRow;