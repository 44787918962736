import PageDiv from "../../PageDiv";
import WorkTypes from "./WorkTypes";
import State from "../../../contexts/workTypes/WorkTypeState";
import UnitNameState from "../../../contexts/unitName/UnitNameState";

function WorkTypesPage() {
	return (
		<PageDiv>
			<State>
				<UnitNameState>
					<WorkTypes />
				</UnitNameState>
			</State>
		</PageDiv>
	)
}

export default WorkTypesPage;