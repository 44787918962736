import PageDiv from "../../../PageDiv";
import Pms from "./Pms";
import AddPm from "./AddPm";
import AddPmjJob from "./AddPmjJob";
import PmjJobs from "./PmjJobs";
import PmState from "../../../../contexts/pms/PmState";
import JobState from "../../../../contexts/jobs/JobState";
import BatchState from "../../../../contexts/batches/BatchState";
import FreelancerState from "../../../../contexts/freelancers/FreelancerState";

function PmsPage() {
	return (
		<div className={"d-flex flex-fill overflow-auto"}>
			<PageDiv>
				<PmState>
					<AddPm />
					<hr />
					<Pms/>
				</PmState>
			</PageDiv>
			<PageDiv>
				<JobState workTypeName={'PMJ'}>
					<BatchState workTypeName={'PMJ'}>
						<FreelancerState workTypeName={'PMJ'}>
							<AddPmjJob />
						</FreelancerState>
					</BatchState>
					<hr />
					<PmjJobs/>
				</JobState>
			</PageDiv>
		</div>
	);
}

export default PmsPage;