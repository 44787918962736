import {useContext, useEffect, useState} from "react";
import Spinner from "../../../Spinner";
import pmContext from "../../../../contexts/pms/pmContext";
import Error from "../../../alerts/Error";

function EditPm ({pm: initialPm}) {
	const [pm, setPm] = useState(initialPm);
	const {editPm, loading} = useContext(pmContext);
	const [error, setError] = useState(null);

	useEffect(() => {
		return () => {
			const backdrops = document.getElementsByClassName("modal-backdrop fade show");
			for (let i = 0; i < backdrops.length; i++) {
				backdrops[i].remove();
			}
		}
	}, []);

	async function onSubmit(evt) {
		evt.preventDefault();
		setError(null);
		try {
			await editPm(pm.id, pm);
		}
		catch (e) {
			console.error(e);
			setError(e.message);
		}
	}

	function onChange(evt) {
		switch (evt.target.type) {
			case "checkbox":
				setPm({
					...pm,
					[evt.target.name]: evt.target.checked
				});
				break;
			default:
				setPm({
					...pm,
					[evt.target.name]: evt.target.value
				});
		}
	}

	return (
		<>
			<button className={"btn btn-warning btn-sm"} data-bs-toggle={"modal"} data-bs-target={"#editPmModal" + pm.id}>Edit pm</button>
			<div className={"modal fade"} id={"editPmModal" + pm.id} tabIndex="-1" aria-labelledby={"editPmModalLabel" + pm.id} aria-hidden="true">
				<div className={"modal-dialog"}>
					<div className={"modal-content"}>
						<form onSubmit={onSubmit}>
							<div className={"modal-header"}>
								<h5 className={"modal-title"} id={"editPmModalLabel" + pm.id}>Edit pm</h5>
								<button type={"button"} className={"btn-close"} data-bs-dismiss={"modal"} />
							</div>
							<div className={"modal-body"}>
								{error && <Error message={error} /> }
								<div className="row mb-3">
									<div className={"col"}>
										<label htmlFor={"firstName" + pm.id} className={"form-label"}>First name:</label>
										<input type="text" id={"firstName" + pm.id} name={"firstName"} value={pm.firstName} className={"form-control"} placeholder={"William"} required={true} onChange={onChange}/>
									</div>
									<div className={"col"}>
										<label htmlFor={"lastName" + pm.id} className={"form-label"}>Last name:</label>
										<input type="text" id={"lastName" + pm.id} value={pm.lastName} name={"lastName"} className={"form-control"} placeholder={"Wong"} required={true} onChange={onChange}/>
									</div>
								</div>

								<div className="row mb-3">
									<div className={"col"}>
										<label htmlFor={"email" + pm.id} className={"form-label"}>Email address:</label>
										<input type="email" id={"email" + pm.id} name={"email"} className={"form-control"} value={pm.email} placeholder={"william.wong@amazon.com"} required={true} onChange={onChange}/>
									</div>
									<div className={"col"}>
										<label htmlFor={"pin" + pm.id} className={"form-label"}>Pin code:</label>
										<input type="text" id={"pin" + pm.id} name={"pin"} className={"form-control"} value={pm.pin} placeholder={"123456"} required={true} onChange={onChange}/>
									</div>
								</div>
							</div>
							<div className={"modal-footer justify-content-between"}>
								<button type={"button"} className={"btn btn-secondary"} data-bs-dismiss={"modal"}>Close</button>
								<button type={"submit"} className={"btn btn-warning"}>{loading ? <Spinner />: 'Save'}</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	)
}

export default EditPm;
