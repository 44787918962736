import {NavLink} from "react-router-dom";
import {AuthContext} from "../contexts/AuthContext";
import {useContext} from "react";

function Navbar() {
	const {setUser, setToken} = useContext(AuthContext);

	const activeClassName = "nav-link active";

	return (
		<div className={"navbar navbar-expand-lg navbar-dark bg-danger"}>
			<div className={"container-fluid"}>
				<a href="#" className={"navbar-brand"}>Datamundi Admin Portal</a>
				<button className="navbar-toggler" type="button" data-bs-toggle="collapse"
						data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
						aria-expanded="false" aria-label="Toggle navigation">
					<span className="navbar-toggler-icon"></span>
				</button>
				<div className="collapse navbar-collapse" id="navbarSupportedContent">
					<ul className="navbar-nav mx-auto mb-2 mb-lg-0">
						<li className="nav-item">
							<NavLink className={({isActive}) => {
								return isActive ? activeClassName : "nav-link";
							}} to="/languages">Languages</NavLink>
						</li>
						<li className="nav-item">
							<NavLink className={({isActive}) => {
								return isActive ? activeClassName : "nav-link";
							}} to="/languagePairs">Language pairs</NavLink>
						</li>
						<li className="nav-item">
							<NavLink className={({isActive}) => {
								return isActive ? activeClassName : "nav-link";
							}} to="/customers">Customers</NavLink>
						</li>
						<li className="nav-item">
							<NavLink className={({isActive}) => {
								return isActive ? activeClassName : "nav-link";
							}} to="/customerTasks">Customer tasks</NavLink>
						</li>
						<li className="nav-item">
							<NavLink className={({isActive}) => {
								return isActive ? activeClassName : "nav-link";
							}} to="/workTypes">Work types</NavLink>
						</li>
						<li className={"nav-item dropdown"}>
							<a className={"nav-link dropdown-toggle" } href="#" id="navbarDropdownDashboard" role="button"
							   data-bs-toggle="dropdown" aria-expanded="false">
								Internal Management
							</a>
							<ul className="dropdown-menu" aria-labelledby="navbarDropdownDashboard">
								<li>
									<NavLink className={({isActive}) => {
										return isActive ? "dropdown-item active" : "dropdown-item";
									}} to="/internalManagement/pms">PMS</NavLink>
								</li>
								<li>
									<NavLink className={({isActive}) => {
										return isActive ? "dropdown-item active" : "dropdown-item";
									}} to="/internalManagement/devs">DEVS</NavLink>
								</li>
							</ul>
						</li>
						<li className="nav-item">
							<NavLink className={({isActive}) => {
								return isActive ? activeClassName : "nav-link";
							}} to="/activity">Activity</NavLink>
						</li>
						<li className="nav-item">
							<NavLink className={({isActive}) => {
								return isActive ? activeClassName : "nav-link";
							}} to="/purchaseOrders">POs</NavLink>
						</li>
					</ul>
					<ul className={"navbar-nav mb-2 mb-lg-0"}>
						<li className="nav-item dropdown">
							<a className={"nav-link dropdown-toggle"} href="#" id="navbarProfileDropdown" role="button"
							   data-bs-toggle="dropdown" aria-expanded="false">
								My profile
							</a>
							<ul className="dropdown-menu" aria-labelledby="navbarProfileDropdown">
								<li>
									<a className="dropdown-item" href={"#"} onClick={(evt) => {
										localStorage.removeItem('token');
										setUser({});
										setToken(null);
									}}>Sign out</a>
								</li>
							</ul>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
}

export default Navbar;
