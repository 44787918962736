import SortableHeader from "../../SortableHeader";
import CustomerRow from "./CustomerRow";
import {useContext, useEffect, useState} from "react";
import resize from "../../../util/resize";
import customerContext from "../../../contexts/customers/customerContext";
import Spinner from "../../Spinner";

function Customers() {
	const {customers, loading} = useContext(customerContext);

	useEffect(() => {
		const items = [];

		try {
			resize(items);
		}
		catch (e) {
			console.error(e);
		}

		return () => {
			for (let item of items) {
				item.element.removeEventListener("mousedown", item.handler);
			}
		}
	}, []);

	return (
		<div className={"table-responsive overflow-auto"}>
			<table className={"table table-striped table-bordered mt-2"} id={"resizeMe"}>
				<thead className={"table-light"}>
				<tr>
					<th>Name</th>
					<th>Description</th>
					<th>Actions</th>
				</tr>
				</thead>
				<tbody>
				{loading && <tr><td colSpan={6}><Spinner /></td></tr>}
					{customers.map(value => {
						return <CustomerRow customer={value} key={value.id}/>;
					})
				}
				</tbody>
			</table>
		</div>
	)
}

export default Customers;
