import React, {useState, useContext, useEffect} from "react";
import {AuthContext} from "../../contexts/AuthContext";
import Error from "../alerts/Error";
import Info from "../alerts/Info";
import {NavLink} from "react-router-dom";

function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [verificationCode, setVerificationCode] = useState('');
    const [showVerificationCode, setShowVerificationCode] = useState(false);
    const [info, setInfo] = useState("");

    const {setUser, setToken} = useContext(AuthContext);

    async function submit(evt) {
        evt.preventDefault();
        setError("");
        setInfo("");

        try {
            const response = await login(email, password);
            if (response.error) {
                setError(response.error);
            }
            else if (response.token) {
                localStorage.setItem('token', response.token);
                setUser(response.user);
                setToken(response.token);
            }
            else {
                if (response.showVerificationCode) {
                    if (response.info) {
                        setInfo(response.info);
                    }
                    setShowVerificationCode(true);
                }
            }
        }
        catch (e) {
            console.error(e);
            setError("Failed to reach the server.");
        }
    }

    useEffect(() => {
        setVerificationCode("");
        setShowVerificationCode(false);
    }, [email, password]);

    async function login(email, password) {
        const response = await fetch(process.env.REACT_APP_endPoint + '/v1/auth', {
            method: "POST",
            headers: {
                'Content-Type': "application/json"
            },
            body: JSON.stringify({email, password, verificationCode})
        });
        return response.json();
    }

    return (
        <form onSubmit={submit}>
            <h2>Login to DAP</h2>
            {error && <Error  message={error} />}
            {info && <Info  message={info} />}

            <div className="my-3">
                <label htmlFor="email" className={"form-label"}>Email address:</label>
                <input type="email" className={"form-control"} id={"email"} value={email} required={true} onChange={(evt) => {
                    setEmail(evt.target.value);
                }} placeholder={"Email address"}/>
            </div>
            <div className="my-3">
                <label htmlFor="password" className={"form-label"}>Password:</label>
                <input type="password" className={"form-control"} id={"password"} value={password} required={true} onChange={(evt) => {
                    setPassword(evt.target.value);
                }} placeholder={"Password"}/>
            </div>

            {showVerificationCode &&
                <div className={"my-3"}>
                    <label htmlFor="verificationCode" className={"form-label"}>Verification code:</label>
                    <input type="text" className={"form-control"} id={"verificationCode"} value={verificationCode} required={true} onChange={(evt) => {
                        setVerificationCode(evt.target.value);
                    }} placeholder={"s1&E8223C?_$~ce:HD6$"}/>
                </div>
            }

            <NavLink to="/forgotPassword">Forgot your password?</NavLink>

            <button type={"submit"} className={"btn btn-primary w-100 mt-2"}>Sign in</button>
        </form>
    );

}

export default Login;