import context from "./subCustomerContext";
import React, {useContext, useEffect, useReducer} from "react";
import reducer from "./subCustomerReducer";
import types from "./subCustomerTypes";
import {NotificationContext} from "../NotificationContext";
import {AuthContext} from "../AuthContext";

export default ({children, customerId}) => {
	const initialState = {
		loading: false,
		subCustomers: [],
		customerId: customerId
	};

	const [state, dispatch] = useReducer(reducer, initialState);
    const {token, setToken} = useContext(AuthContext);
	const {addToast} = useContext(NotificationContext);

	useEffect(() => {
		const abortController = new AbortController();

		(async () => {
			try {
				await getSubCustomers(abortController, customerId);
			}
			catch (e) {
				console.error(e);
				dispatch({
					type: types.SET_LOADING
				});
			}
		})();

		return () => {
			abortController.abort();
		};
	}, []);

	// Get customers
	async function getSubCustomers(abortController, customerId) {
		dispatch({
			type: types.SET_LOADING
		});

		const response = await fetch(process.env.REACT_APP_endPoint + '/graphql', {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				'Authorization': 'Bearer ' + token
			},
			body: JSON.stringify({
				query: `
					query getSubCustomers{
						getSubCustomers(customerId: ${customerId}){
							id
							email
							firstName
							lastName
							customerId
							rights
						}
					}`
			}),
			signal: abortController.signal
		});
		if (response.status === 401) {
			setToken(null);
		}
		else {
			const json = await response.json();

			if (json.errors) {
				for (let i = 0; i < json.errors.length; i++) {
					const {data} = json.errors[i];

					if (data && data.length > 0) {
						for (let j = 0; j < data.length; j++) {
							addToast({
								message: json.errors[i].message,
								description: data[j]
							});
						}
					} else {
						addToast({
							message: json.errors[i].message,
							description: ""
						});
					}
				}
				dispatch({
					type: types.SET_LOADING
				});
			} else {
				dispatch({
					type: types.SET_SUBCUSTOMERS,
					payload: json.data.getSubCustomers
				});
			}
		}
	}

	// Remove subCustomer
	async function removeSubCustomer(subCustomerId) {
		dispatch({
			type: types.SET_LOADING
		});

		const response = await fetch(process.env.REACT_APP_endPoint + '/graphql', {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				'Authorization': 'Bearer ' + token
			},
			body: JSON.stringify({
				query: `
					mutation removeSubCustomer ($subCustomerId: ID!){
						removeSubCustomer(subCustomerId:$subCustomerId) 
					}
				`,
				variables: {
					subCustomerId: subCustomerId
				}
			})
		});
		if (response.status === 401) {
			setToken(null);
		}
		else {
			const json = await response.json();

			if (json.errors) {
				for (let i = 0; i < json.errors.length; i++) {
					const {data} = json.errors[i];

					if (data && data.length > 0) {
						for (let j = 0; j < data.length; j++) {
							addToast({
								message: json.errors[i].message,
								description: data[j]
							});
						}
					} else {
						addToast({
							message: json.errors[i].message,
							description: ""
						});
					}
				}
				dispatch({
					type: types.SET_LOADING
				});
			} else {
				dispatch({
					type: types.SET_SUBCUSTOMERS,
					payload: [...state.subCustomers.filter(value => value.id !== subCustomerId)]
				});
			}
		}
	}

	// Add subCustomer
	async function createSubCustomer(subCustomer) {
		dispatch({
			type: types.SET_LOADING
		});

		console.log();

		const response = await fetch(process.env.REACT_APP_endPoint + '/graphql', {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				'Authorization': 'Bearer ' + token
			},
			body: JSON.stringify({
				query: `mutation createSubCustomer($subCustomer: CreateSubCustomerInput!) {
						createSubCustomer(subCustomer: $subCustomer) {
							id
							firstName
							lastName
							email
							customerId
							rights
						}
					}`,
				variables: {
					subCustomer: subCustomer
				}
			})
		});
		if (response.status === 401) {
			setToken(null);
		}
		else {
			const json = await response.json();

			if (json.errors) {
				for (let i = 0; i < json.errors.length; i++) {
					const {data} = json.errors[i];

					if (data && data.length > 0) {
						for (let j = 0; j < data.length; j++) {
							addToast({
								message: json.errors[i].message,
								description: data[j]
							});
						}
					} else {
						addToast({
							message: json.errors[i].message,
							description: ""
						});
					}
				}

				dispatch({
					type: types.SET_LOADING
				});
			} else {
				dispatch({
					type: types.SET_SUBCUSTOMERS,
					payload: [...state.subCustomers, json.data.createSubCustomer]
				});
			}
		}
	}

	// Edit customer
	async function editSubCustomer(subCustomerId, subCustomer) {
		dispatch({
			type: types.SET_LOADING
		});

		const response = await fetch(process.env.REACT_APP_endPoint + '/graphql', {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				'Authorization': 'Bearer ' + token
			},
			body: JSON.stringify({
				query: `
					mutation {
						editSubCustomer(subCustomerId: ${subCustomerId}, subCustomer: {
							email: "${subCustomer.email}",
							firstName: "${subCustomer.firstName}",
							lastName: "${subCustomer.lastName}",
							customerId: ${subCustomer.customerId}
							rights: "${subCustomer.rights}"
						}) {
							id
							email
							firstName
							lastName
							customerId
							rights
						}
					}
				`
			})
		});
		if (response.status === 401) {
			setToken(null);
		}
		else {
			const json = await response.json();

			if (json.errors) {
				for (let i = 0; i < json.errors.length; i++) {
					const {data} = json.errors[i];

					if (data && data.length > 0) {
						for (let j = 0; j < data.length; j++) {
							addToast({
								message: json.errors[i].message,
								description: data[j]
							});
						}
					} else {
						addToast({
							message: json.errors[i].message,
							description: ""
						});
					}
				}
				dispatch({
					type: types.SET_LOADING
				});
			} else {
				const newSubCustomers = [...state.subCustomers];
				newSubCustomers[newSubCustomers.findIndex(value => value.id === subCustomerId)] = json.data.editSubCustomer;
				dispatch({
					type: types.SET_SUBCUSTOMERS,
					payload: newSubCustomers
				});
			}
		}
	}

	return (
		<context.Provider value={{
			...state,
			getSubCustomers: getSubCustomers,
			createSubCustomer: createSubCustomer,
			editSubCustomer: editSubCustomer,
			removeSubCustomer: removeSubCustomer,
		}}>
			{children}
		</context.Provider>
	);
};
