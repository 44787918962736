import {useContext, useEffect, useState} from "react";
import Spinner from "../../../Spinner";
import batchContext from "../../../../contexts/batches/batchContext";
import freelancerContext from "../../../../contexts/freelancers/freelancerContext";
import jobContext from "../../../../contexts/jobs/jobContext";
import Error from "../../../alerts/Error";

const initialDevJob = {
	freelancerId: '',
	batchId: '',
	price: '',
	description: ''
};

function AddDevJob() {
	const [devJob, setDevJob] = useState(initialDevJob);
	const {batches} = useContext(batchContext);
	const {freelancers} = useContext(freelancerContext);
	const {loading, createJob} = useContext(jobContext);
	const [error, setError] = useState(null);

	async function onSubmit(evt) {
		evt.preventDefault();
		setError(null);

		try {
			await createJob(devJob);
		}
		catch (e) {
			console.error(e);
			setError(e.message);
		}
		setDevJob(initialDevJob);
	}

	function onChange(evt) {
		switch (evt.target.type) {
			case "checkbox":
				setDevJob({
					...devJob,
					[evt.target.id]: evt.target.checked
				});
				break;
			default:
				setDevJob({
					...devJob,
					[evt.target.id]: evt.target.value
				});
		}
	}

	return (
		<div>
			<button className={"btn btn-success"} data-bs-toggle={"modal"} data-bs-target={"#addDevJobModal"}>Add dev job</button>
			<div className={"modal fade"} id={"addDevJobModal"} tabIndex="-1" aria-labelledby="addDevJobModalLabel" aria-hidden="true">
				<div className={"modal-dialog modal-lg"}>
					<div className={"modal-content"}>
						<form onSubmit={onSubmit}>
							<div className={"modal-header"}>
								<h5 className={"modal-title"} id={"addDevJobModalLabel"}>Add dev job</h5>
								<button type={"button"} className={"btn-close"} data-bs-dismiss={"modal"} />
							</div>
							<div className={"modal-body"}>
								{error && <Error message={error} /> }
								<div className="row mb-3">
									<div className={"col"}>
										<label htmlFor="freelancerId" className={"form-label"}>Developer (freelancers with DEV work type):</label>
										<select id="freelancerId" className={"form-select"} value={devJob.freelancerId} onChange={onChange} required={true}>
											<option value="">Select a DEV</option>
											{freelancers.filter(value => value.hourlyRate).map(value => {
												return <option key={value.id} value={value.id}>{value.fullName}</option>;
											})}
										</select>
									</div>
									<div className={"col"}>
										<label htmlFor="batchId" className={"form-label"}>Batch:</label>
										<select id="batchId" className={"form-select"} value={devJob.batchId} onChange={onChange} required={true}>
											<option value="">Select a batch</option>
											{batches.map(value => {
												return <option key={value.id} value={value.id}>{value.name}</option>;
											})}
										</select>
									</div>
								</div>

								<div className="row mb-3">
									<div className={"col"}>
										<label htmlFor="price" className={"form-label"}>Value:</label>
										<input step="0.01" type="number" id={"price"} className={"form-control"} value={devJob.price} placeholder={""} required={true} onChange={onChange}/>
									</div>
									<div className={"col"}>
										<label htmlFor="description" className={"form-label"}>Description:</label>
										<textarea id={"description"} value={devJob.description} onChange={onChange} className={"form-control"}/>
									</div>
								</div>
							</div>
							<div className={"modal-footer justify-content-between"}>
								<button type={"button"} className={"btn btn-secondary"} data-bs-dismiss={"modal"}>Close</button>
								<button type={"submit"} className={"btn btn-success"}>{loading ? <Spinner />: 'Add dev job'}</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AddDevJob;
