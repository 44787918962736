import types from "./customerTaskTypes";

export default (state, action) => {
	switch (action.type) {
		case types.SET_LOADING:
			return {
				...state,
				loading: !state.loading
			};
		case types.SET_CUSTOMER_TASKS:
			return {
				...state,
				loading: false,
				customerTasks: action.payload
			};
		case types.SET_PAGE_INFO:
			return {
				...state,
				pageInfo: action.payload
			};
		case types.SET_FILTERS:
			return {
				...state,
				filters: action.payload
			}
		default:
			return state;
	}
};