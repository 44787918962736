import types from "./purchaseOrderTypes";

export default (state, action) => {
	switch (action.type) {
		case types.SET_LOADING:
			return {
				...state,
				loading: !state.loading
			};
		case types.SET_PURCHASE_ORDERS:
			return {
				...state,
				loading: false,
				purchaseOrders: action.payload
			};
		case types.SET_PAGE_INFO:
			return {
				...state,
				pageInfo: action.payload
			};
		case types.SET_FILTERS:
			return {
				...state,
				filters: action.payload
			};
		default:
			return state;
	}
};
