import types from "./jobTypes";

export default (state, action) => {
	switch (action.type) {
		case types.SET_LOADING:
			return {
				...state,
				loading: !state.loading
			};
		case types.SET_JOBS:
			return {
				...state,
				loading: false,
				jobs: action.payload
			};
		default:
			return state;
	}
};