import {useContext, useEffect, useState} from "react";
import Spinner from "../../../Spinner";
import jobContext from "../../../../contexts/jobs/jobContext";
import Error from "../../../alerts/Error";

function RemoveDevJob ({devJob}) {
	const {loading, removeJob} = useContext(jobContext);
	const [error, setError] = useState(null);

	useEffect(() => {
		return () => {
			const backdrops = document.getElementsByClassName("modal-backdrop fade show");
			for (let i = 0; i < backdrops.length; i++) {
				backdrops[i].remove();
			}
		}
	}, []);

	async function onSubmit(evt) {
		evt.preventDefault();
		setError(null);
		try {
			await removeJob(devJob.id);
		}
		catch (e) {
			console.error(e);
			setError(e.message);
		}
	}

	return (
		<>
			<button className={"btn btn-danger btn-sm"} data-bs-toggle={"modal"} data-bs-target={"#removeDevJobModal" + devJob.id}>Remove dev job</button>
			<div className={"modal fade"} id={"removeDevJobModal" + devJob.id} tabIndex="-1" aria-labelledby={"removeDevJobModalLabel" + devJob.id} aria-hidden="true">
				<div className={"modal-dialog"}>
					<div className={"modal-content"}>
						<form onSubmit={onSubmit}>
							<div className={"modal-header"}>
								<h5 className={"modal-title"} id={"removeDevJobModalLabel" + devJob.id}>Remove dev job</h5>
								<button type={"button"} className={"btn-close"} data-bs-dismiss={"modal"} />
							</div>
							<div className={"modal-body"}>
								{error && <Error message={error} /> }
								<span>Are you sure you want to remove {devJob.name}?</span>
							</div>
							<div className={"modal-footer justify-content-between"}>
								<button type={"button"} className={"btn btn-secondary"} data-bs-dismiss={"modal"}>Close</button>
								<button type={"submit"} className={"btn btn-danger"}>{loading ? <Spinner />: 'Remove dev job'}</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	)
}

export default RemoveDevJob;
