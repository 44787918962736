import {useEffect, useState} from "react";
import Spinner from "../../Spinner";

function EditLanguage ({language: initialLanguage, dispatch}) {
	const [language, setLanguage] = useState(initialLanguage);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLanguage(initialLanguage);
	}, [initialLanguage]);

	async function onSubmit(evt) {
		evt.preventDefault();
		setLoading(true);
		setLoading(false);
	}

	function onChange(evt) {
		switch (evt.target.type) {
			case "checkbox":
				setLanguage({
					...language,
					[evt.target.name]: evt.target.checked
				});
				break;
			default:
				setLanguage({
					...language,
					[evt.target.name]: evt.target.value
				});
		}
	}

	return (
		<>
			<button className={"btn btn-warning btn-sm"} data-bs-toggle={"modal"} data-bs-target={"#editLanguageModal" + language.id}>Edit language</button>
			<div className={"modal fade"} id={"editLanguageModal" + language.id} tabIndex="-1" aria-labelledby={"editLanguageModalLabel" + language.id} aria-hidden="true">
				<div className={"modal-dialog modal-lg"}>
					<div className={"modal-content"}>
						<form onSubmit={onSubmit}>
							<div className={"modal-header"}>
								<h5 className={"modal-title"} id={"editLanguageModalLabel" + language.id}>Edit language</h5>
								<button type={"button"} className={"btn-close"} data-bs-dismiss={"modal"} />
							</div>
							<div className={"modal-body"}>
								<div className={"mb-3"}>
									<label htmlFor={"name" + language.id} className={"form-label"}>Name:</label>
									<input name={"name"} type="text" id={"name" + language.id} className={"form-control"} placeholder={"Language name"} required={true} value={language.name} onChange={onChange}/>
								</div>
								<div className="row mb-3">
									<div className={"col"}>
										<label htmlFor={"shortCode" + language.id} className={"form-label"}>Short code:</label>
										<input name={"shortCode"} type="text" id={"shortCode" + language.id} className={"form-control"} placeholder={"Language short code"} required={true} value={language.shortCode} onChange={onChange}/>
									</div>
									<div className={"col"}>
										<label htmlFor={"tag" + language.id} className={"form-label"}>Tag:</label>
										<input name={"tag"} type="text" id={"tag" + language.id} className={"form-control"} placeholder={"Language tag"} required={true} value={language.tag} onChange={onChange}/>
									</div>
								</div>

								<div className={"mb-3"}>
									<label htmlFor={"languageDirectionId" + language.id} className={"form-label"}>Direction:</label>
									<select name={"languageDirectionId"} id={"languageDirectionId" + language.id} className={"form-select"} required={true} value={language.languageDirectionId} onChange={onChange}>
										<option value="">Select an option</option>
										<option value="1">Left to right</option>
										<option value="2">Right to left</option>
									</select>
								</div>
								<div className="form-check">
									<input className="form-check-input" name={"wordToken"} type="checkbox" value="" id={"wordToken" + language.id} checked={language.wordToken} onChange={onChange}/>
									<label className="form-check-label" htmlFor={"wordToken" + language.id}>
										Language word token
									</label>
								</div>
							</div>
							<div className={"modal-footer justify-content-between"}>
								<button type={"button"} className={"btn btn-secondary"} data-bs-dismiss={"modal"}>Close</button>
								<button type={"submit"} className={"btn btn-warning"} disabled={loading}>{loading ? <Spinner />: 'Save'}</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	)
}

export default EditLanguage;
