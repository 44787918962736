import context from "./languageContext";
import React, {useContext, useEffect, useReducer} from "react";
import reducer from "./languageReducer";
import types from "./languageTypes";
import {NotificationContext} from "../NotificationContext";
import {AuthContext} from "../AuthContext";

export default ({children}) => {
	const initialState = {
		loading: false,
		languages: []
	};

	const [state, dispatch] = useReducer(reducer, initialState);
    const {token, setToken} = useContext(AuthContext);
	const {addToast} = useContext(NotificationContext);

	useEffect(() => {
		const abortController = new AbortController();

		(async () => {
			try {
				await getLanguages(abortController);
			}
			catch (e) {
				console.error(e);
				dispatch({
					type: types.SET_LOADING
				});
			}
		})();

		return () => {
			abortController.abort();
		};
	}, []);

	// Get languages
	async function getLanguages(abortController) {
		dispatch({
			type: types.SET_LOADING
		});

		const response = await fetch(process.env.REACT_APP_endPoint + '/graphql', {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				'Authorization': 'Bearer ' + token
			},
			body: JSON.stringify({
				query: `
					query getLanguages {
					  getLanguages {
						id
						name
						shortCode
						langTag
						direction
						wordToken
					  }
					}
				`
			}),
			signal: abortController.signal
		});
		if (response.status === 401) {
			setToken(null);
		}
		else {
			const json = await response.json();

			if (json.errors) {
				for (let i = 0; i < json.errors.length; i++) {
					const {data} = json.errors[i];

					if (data && data.length > 0) {
						for (let j = 0; j < data.length; j++) {
							addToast({
								message: json.errors[i].message,
								description: data[j]
							});
						}
					} else {
						addToast({
							message: json.errors[i].message,
							description: ""
						});
					}
				}
				dispatch({
					type: types.SET_LOADING
				});
			} else {
				dispatch({
					type: types.SET_LANGUAGES,
					payload: json.data.getLanguages
				});
			}
		}
	}

	// Remove language
	async function removeLanguage(languageId) {
		dispatch({
			type: types.SET_LOADING
		});

		const response = await fetch(process.env.REACT_APP_endPoint + '/graphql', {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				'Authorization': 'Bearer ' + token
			},
			body: JSON.stringify({
				query: `
					mutation removeLanguage($languageId: ID!) {
					  removeLanguage(languageId:$languageId)
					}
				`,
				variables: {
					languageId
				}
			})
		});
		if (response.status === 401) {
			setToken(null);
		}
		else {
			const json = await response.json();

			if (json.errors) {
				for (let i = 0; i < json.errors.length; i++) {
					const {data} = json.errors[i];

					if (data && data.length > 0) {
						for (let j = 0; j < data.length; j++) {
							addToast({
								message: json.errors[i].message,
								description: data[j]
							});
						}
					} else {
						addToast({
							message: json.errors[i].message,
							description: ""
						});
					}
				}
				dispatch({
					type: types.SET_LOADING
				});
			} else {
				dispatch({
					type: types.SET_LANGUAGES,
					payload: [...state.languages.filter(value => value.id !== languageId)]
				});
			}
		}
	}

	// Add language
	async function createLanguage(language) {
		dispatch({
			type: types.SET_LOADING
		});

		const response = await fetch(process.env.REACT_APP_endPoint + '/graphql', {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				'Authorization': 'Bearer ' + token
			},
			body: JSON.stringify({
				query: `
					mutation createLanguage($language: CreateLanguageInput!) {
					  createLanguage(language:$language) {
						id
						name
						shortCode
						langTag
						direction
						wordToken
					  }
					}
				`,
				variables: {
					language: {
						...language
					}
				}
			})
		});
		if (response.status === 401) {
			setToken(null);
		}
		else {
			const json = await response.json();

			if (json.errors) {
				for (let i = 0; i < json.errors.length; i++) {
					const {data} = json.errors[i];

					if (data && data.length > 0) {
						for (let j = 0; j < data.length; j++) {
							addToast({
								message:json.errors[i].message,
								description: data[j]
							});
						}
					}
					else {
						addToast({
							message: json.errors[i].message,
							description: ""
						});
					}
				}

				dispatch({
					type: types.SET_LOADING
				});
			}
			else {
				dispatch({
					type: types.SET_LANGUAGES,
					payload: [...state.languages, json.data.createLanguage]
				});
			}
		}
	}

	return (
		<context.Provider value={{
			...state,
			getLanguages,
			createLanguage,
			removeLanguage
		}}>
			{children}
		</context.Provider>
	);
};