import context from "./workTypeContext";
import React, {useContext, useEffect, useReducer} from "react";
import reducer from "./workTypeReducer";
import types from "./workTypeTypes";
import {NotificationContext} from "../NotificationContext";
import {AuthContext} from "../AuthContext";

export default ({children}) => {
	const initialState = {
		loading: false,
		workTypes: []
	};

	const [state, dispatch] = useReducer(reducer, initialState);
    const {token, setToken} = useContext(AuthContext);
	const {addToast} = useContext(NotificationContext);

	useEffect(() => {
		const abortController = new AbortController();

		(async () => {
			try {
				await getWorkTypes(abortController);
			}
			catch (e) {
				console.error(e);
				dispatch({
					type: types.SET_LOADING
				});
			}
		})();

		return () => {
			abortController.abort();
		};
	}, []);

	// Get customers
	async function getWorkTypes(abortController) {
		dispatch({
			type: types.SET_LOADING
		});

		const response = await fetch(process.env.REACT_APP_endPoint + '/graphql', {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				'Authorization': 'Bearer ' + token
			},
			body: JSON.stringify({
				query: `
					query getWorkTypes{
						getWorkTypes{
							id
							name
							unitValue
							minUnitValue
							maxUnitValue
							unitName {
								id
								name
							}
							groupA
							unitNameId
							groupB
							groupC
							problemCount
							description
						}
					}`
			}),
			signal: abortController.signal
		});
		if (response.status === 401) {
			setToken(null);
		}
		else {
			const json = await response.json();

			if (json.errors) {
				for (let i = 0; i < json.errors.length; i++) {
					const {data} = json.errors[i];

					if (data && data.length > 0) {
						for (let j = 0; j < data.length; j++) {
							addToast({
								message: json.errors[i].message,
								description: data[j]
							});
						}
					} else {
						addToast({
							message: json.errors[i].message,
							description: ""
						});
					}
				}
				dispatch({
					type: types.SET_LOADING
				});
			} else {
				dispatch({
					type: types.SET_WORK_TYPES,
					payload: json.data.getWorkTypes
				});
			}
		}
	}

	// Edit customer
	async function editWorkType(workTypeId, workType) {
		dispatch({
			type: types.SET_LOADING
		});

		const response = await fetch(process.env.REACT_APP_endPoint + '/graphql', {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				'Authorization': 'Bearer ' + token
			},
			body: JSON.stringify({
				query: `
					mutation editWorkType ($workTypeId: ID!, $workType: EditWorkTypeInput!) {
						editWorkType(workTypeId: $workTypeId, workType:$workType) {
							id
							name
							unitValue
							minUnitValue
							maxUnitValue
							unitName {
								id
								name
							}
							groupA
							groupB
							groupC
							problemCount
							description
							unitNameId
						}
					}
				`,
				variables: {
					workType: {
						unitNameId: workType.unitNameId,
						unitValue: parseFloat(workType.unitValue),
						minUnitValue: parseFloat(workType.minUnitValue),
						maxUnitValue: parseFloat(workType.maxUnitValue),
						groupA: parseFloat(workType.groupA),
						groupB: parseFloat(workType.groupB),
						groupC: parseFloat(workType.groupC),
						problemCount: parseInt(workType.problemCount),
						description: workType.description
					},
					workTypeId
				}
			})
		});
		if (response.status === 401) {
			setToken(null);
		}
		else {
			const json = await response.json();

			if (json.errors) {
				for (let i = 0; i < json.errors.length; i++) {
					const {data} = json.errors[i];

					if (data && data.length > 0) {
						for (let j = 0; j < data.length; j++) {
							addToast({
								message: json.errors[i].message,
								description: data[j]
							});
						}
					} else {
						addToast({
							message: json.errors[i].message,
							description: ""
						});
					}
				}
				dispatch({
					type: types.SET_LOADING
				});
			} else {
				const newWorkTypes = [...state.workTypes];
				newWorkTypes[newWorkTypes.findIndex(value => value.id === workTypeId)] = json.data.editWorkType;
				dispatch({
					type: types.SET_WORK_TYPES,
					payload: newWorkTypes
				});
			}
		}
	}

	return (
		<context.Provider value={{
			...state,
			getWorkTypes,
			editWorkType,
		}}>
			{children}
		</context.Provider>
	);
};