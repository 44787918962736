import React, {useState} from "react";
import SortContext from "./sortContext";

export default ({children, initialColumn = 'id'}) => {
	const [asc, setAsc] = useState(false);
	const [desc, setDesc] = useState(true);
	const [sortBy, setSortBy] = useState(initialColumn);

	return (
		<SortContext.Provider value={{
			asc,
			desc,
			sortBy,
			setAsc,
			setDesc,
			setSortBy
		}}>
			{children}
		</SortContext.Provider>
	);
}
