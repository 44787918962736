import Navbar from "./components/NavBar";
import {BrowserRouter as Router, Routes,  Navigate, Route} from "react-router-dom";
import Footer from "./components/Footer";
import LanguagesPage from "./components/pages/languages";
import LanguagePairsPage from "./components/pages/languagePairs";
import CustomersPage from "./components/pages/customers";
import CustomerTasksPage from "./components/pages/customerTasks";
import PMSPage from "./components/pages/internalManagement/pms";
import DEVSPage from "./components/pages/internalManagement/devs";

import {useState, useEffect} from "react";
import {v4 as uuidv4} from "uuid";
import {NotificationContext} from "./contexts/NotificationContext";
import Notifications from "./components/Notifications";
import Activity from "./components/pages/activity";
import PurchaseOrdersPage from "./components/pages/purchaseOrders";
import WorkTypesPage from "./components/pages/workTypes";
import {AuthContext} from "./contexts/AuthContext";
import Login from "./components/auth/Login";
import Container from "./components/auth/Container";
import ResetPassword from "./components/auth/ResetPassword";
import ForgotPassword from "./components/auth/Forgot";
import SubCustomersPage from "./components/pages/subCustomers";

function App() {
	const [toasts, setToasts] = useState([]);
	const [user, setUser] = useState({});
	const [token, setToken] = useState(null);

	useEffect(() => {
		(async () => {
			const tokenFromLocalStorage = localStorage.getItem('token');
			if (tokenFromLocalStorage) {
				try {
					const response = await fetchUser(tokenFromLocalStorage);
					if (!response.error && response.user) {
						setUser(response.user);
						setToken(tokenFromLocalStorage);
					}
				}
				catch (e) {
					console.error(e);
				}
			}
		})();
	}, []);

	async function fetchUser(token) {
		const response = await fetch(process.env.REACT_APP_endPoint + '/v1/auth', {
			method: "GET",
			headers: {
				'Authorization': 'Bearer ' + token
			}
		});

		return response.json();
	}

	function addToast({message, description}) {
		setToasts((toasts) => {
			return [...toasts, {message,  description, id: uuidv4()}];
		});
	}
	function removeToast(id) {
		setToasts((toasts) => {
			return toasts.filter(value => value.id !== id)
		});
	}

	return (
		<NotificationContext.Provider value={{
			toasts,
			setToasts,
			addToast,
			removeToast
		}}>
			<Notifications />
			<AuthContext.Provider value={{
				token,
				user,
				setToken,
				setUser
			}}>
				{token ? (<>
				<Router>
					<Navbar />
					<Routes>
						<Route path={'/languages'} element={<LanguagesPage />}/>
						<Route path={'/languagePairs'} element={<LanguagePairsPage />} />
						<Route path={'/customers'} element={<CustomersPage />}/>
						<Route path={'/subCustomers/:customerId/:customerFullName'} element={<SubCustomersPage />}/>
						<Route path={'/customerTasks'} element={<CustomerTasksPage />} />
						<Route path={'/workTypes'} element={<WorkTypesPage />} />

						<Route path={'/internalManagement/pms'} element={<PMSPage />} />
						<Route path={'/internalManagement/devs'} element={<DEVSPage />} />
						<Route path={'/activity'} element={<Activity />} />
						<Route path={'/purchaseOrders'} element={<PurchaseOrdersPage />} />
						<Route path="*" element={<Navigate to="/customerTasks" replace />} />
					</Routes>
					<Footer />
				</Router>
				</>):
					<Container>
						<Router>
							<Routes>
								<Route path={'/signIn'} element={<Login />}/>
								<Route path={'/resetPassword'} element={<ResetPassword />}/>
								<Route path={'/forgotPassword'} element={<ForgotPassword />}/>
								<Route path="*" element={<Navigate to="/signIn" replace />} />
							</Routes>
						</Router>
					</Container>
				}
			</AuthContext.Provider>
		</NotificationContext.Provider>
	);
}


export default App;
