import background from "../../images/background.jpg";
import React from "react";

export default function Container({children}) {
    return (
        <div className="row  flex-fill m-0" style={{backgroundImage: 'url(' + background + ')', backgroundSize: 'cover'}}>
            <div className="col p-5"  >
                <div style={{marginTop: '4em'}}>
                    <h2>Welcome to the admin panel at Datamundi</h2>
                    <p>Unlock the power of number magic</p>
                </div>
            </div>
            <div className="col  p-5" style={{marginTop: 'calc(100px + 4em)'}}>
                {children}
            </div>
        </div>
    );
};