import types from "./workTypeTypes";

export default (state, action) => {
	switch (action.type) {
		case types.SET_LOADING:
			return {
				...state,
				loading: !state.loading
			};
		case types.SET_WORK_TYPES:
			return {
				...state,
				loading: false,
				workTypes: action.payload
			};
		default:
			return state;
	}
};